// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import * as React from "react";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import SearchAndSelectDialog from "./SearchAndSelectDialog";
import NewIdDialog from "./NewIdDialog";
import { Roger } from "@fe/roger-core";
import type { Quad } from "@rdfjs/types";
import type { Store } from "n3";
import type { SparqlConfig } from "../sparql/update";
import { updateResource } from "../sparql/update";

interface SingleFrameProps {
  schema: Store;
  idNamespace?: string;
  config?: SparqlConfig;
  initialQuads: Quad[];
}

export default function SingleFrame({
  schema: schemaStore,
  idNamespace = "http://example.org/",
  config,
  initialQuads,
}: //initialQuads,
  SingleFrameProps) {
  /** Visibility of the search result dialog. */
  const [searchAndSelectDialogOpen, setSearchAndSelectDialogOpen] =
    React.useState<boolean>(false);
  /** Visibility of the new ID dialog. */
  const [newIdDialogOpen, setNewIdDialogOpen] = React.useState<boolean>(false);
  /** An array of Quads to store data retrieved from SPARQL endpoint. */
  const [dataQuads, setDataQuads] = React.useState<Array<Quad>>([]);
  /** Track the "changed" state to prevent data loss on "New" or "Open". */
  const [changed, setChanged] = React.useState<boolean>(false);

  //console.log("initial", initialQuads);
  /** Open dialog. */
  function handleClickOpenButton() {
    setSearchAndSelectDialogOpen(true);
  }

  function handleClickNewButton() {
    setNewIdDialogOpen(true);
  }

  async function handleSave(quads: Quad[]) {
    await updateResource(quads, config);
  }

  //console.log("initialize", initialQuads);
  return (
    <Stack direction="row" spacing={2}>
      {/* <ButtonGroup orientation="vertical">
        <Button onClick={handleClickNewButton}>New</Button>
        <Button onClick={handleClickOpenButton}>Open</Button>
      </ButtonGroup>
      <NewIdDialog
        isOpen={[newIdDialogOpen, setNewIdDialogOpen]}
        ns={idNamespace}
        schema={schemaStore}
        onCreate={[dataQuads, setDataQuads]}
      />
      <SearchAndSelectDialog
        isOpen={[searchAndSelectDialogOpen, setSearchAndSelectDialogOpen]}
        setData={setDataQuads}
        single
      /> */}

      <Roger
        schema={schemaStore}
        data={initialQuads}
        onSave={config ? handleSave : undefined}
        useChanged={[changed, setChanged]}
      />
    </Stack>
  );
}
