// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const Section = ({ title, children }) => (
    <Card variant="outlined" sx={{ marginBottom: 2 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>
            {children}
        </CardContent>
    </Card>
);

export const MainDetails = ({ details }) => (
    <Section title="Main Details">

        <Typography><strong>Text:</strong> {details.exact}</Typography>
        <Typography><strong>Created at:</strong> {details.created}</Typography>
        <Typography><strong>Note:</strong> {details.note}</Typography>
        <Typography><strong>First Proposer:</strong> {details.firstProposer}</Typography>
        <Typography><strong>r21_identifies:</strong> {details.r21_identifies}</Typography>
        <Typography><strong>Formal Appearance:</strong> {details.formalAppearance}</Typography>
        <Typography><strong>Has Quotation Marks:</strong> {details.hasQuotationMarks}</Typography>
        <Typography><strong>Title Stated:</strong> {details.titleStated}</Typography>
        <Typography><strong>Author Stated:</strong> {details.authorStated}</Typography>
        <Typography><strong>Literary Source:</strong> {details.literarysource}</Typography>

    </Section>
);


export const AuthorDetails = ({ details }) => (
    <Section title="Author">
        <Typography><strong>Author URI:</strong> {details.author}</Typography>
        <Typography><strong>Author Name:</strong> {details.authorName}</Typography>
    </Section>
);

export const LiterarySourceDetails = ({ details }) => (
    <Section title="Literary Source">
        <Typography><strong>Literary Source:</strong> {details.literarysource}</Typography>
        <Typography><strong>Has Wording:</strong> {details.has_wording}</Typography>
        <Typography><strong>Context:</strong> {details.sorcontext}</Typography>
        <Typography><strong>Part:</strong> {details.resourcepart}</Typography>
        <Typography><strong>Original Link:</strong> {details.originalLink}</Typography>
        <Typography><strong>Modified Link:</strong> {details.modifiedLink}</Typography>
        <Typography><strong>Part of Instance:</strong> {details.sourcepartof}</Typography>
    </Section>
);

export const EgotextDetails = ({ details }) => (
    <Section title="Egotext">
        <Typography><strong>Egotext:</strong> {details.egotext}</Typography>
        <Typography><strong>Part:</strong> {details.egotextpart}</Typography>
        <Typography><strong>Part of Instance:</strong> {details.sourcepartof}</Typography>
    </Section>
);
