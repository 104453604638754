// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import { TextField, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/lab/Autocomplete';
import { AllAnnotationsQuery } from '../../data/Queries.ts';
import { FetchData } from '../FetchData.tsx';


const parseAnnotations = (results) => {
    return results.map(binding => ({
        annotation: binding.annotation.value,
        exact: binding.exact?.value ?? null,
        note: binding.note?.value ?? null,
        author: binding.author?.value,
        authorName: binding.authorName?.value ?? null,
        created: binding.created?.value ?? null,
    }));
};


const Authors: React.FC = () => {
    const [annotations, setAnnotations] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [authorAnnotations, setAuthorAnnotations] = useState([]);

    useEffect(() => {
        const Request = {
            query: AllAnnotationsQuery,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;
            const annotations = parseAnnotations(bindings);
            setAnnotations(annotations);
        };

        fetch();
    }, []);

    const handleAuthorChange = (event, value) => {
        setSelectedAuthor(value);
        if (value) {
            const filteredAnnotations = annotations.filter(annotation => annotation.authorName === value.authorName);
            setAuthorAnnotations(filteredAnnotations);
        } else {
            setAuthorAnnotations([]);
        }
    };

    return (


        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Search by Author</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Autocomplete
                        options={[...new Set(annotations.map(annotation => annotation.authorName))].map(authorName => ({ authorName }))}
                        getOptionLabel={(option) => option.authorName}
                        onChange={handleAuthorChange}
                        renderInput={(params) => <TextField {...params} label="Author Name" variant="outlined" margin="normal" fullWidth />}
                        sx={{ width: '100%' }}
                    />
                    {selectedAuthor && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Exact</TableCell>
                                    <TableCell>Author</TableCell>
                                    <TableCell>Created</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {authorAnnotations.map(annotation => (
                                    <TableRow key={annotation.annotation}>
                                        <TableCell>{annotation.exact}</TableCell>
                                        <TableCell>{annotation.authorName}</TableCell>
                                        <TableCell>{annotation.created}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>

    );
};

export default Authors;

