// SPDX-FileCopyrightText: 2022 CodedThemes (Mantis Theme)
//
// SPDX-License-Identifier: MIT

// ==============================|| OVERRIDES - TABS ||============================== //

export default function Tabs() {
  return {
    MuiTabs: {
      styleOverrides: {
        vertical: {
          overflow: 'visible'
        }
      }
    }
  };
}
