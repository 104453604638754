// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/lab/Autocomplete';
import Prefixes from '../../data/Prefixes.ts';
import { AllAnnotationsQuery } from '../../data/Queries.ts';
import { FetchData } from '../FetchData.tsx';


const parseAnnotations = (results) => {
    return results.map(binding => ({
        annotation: binding.annotation.value,
        exact: binding.exact?.value ?? null,
        note: binding.note?.value ?? null,
        author: binding.author?.value,
        authorName: binding.authorName?.value ?? null,
        created: binding.created?.value ?? null,
    }));
};

const deleteAnnotation = async (annotationData) => {
    try {
        const subject = annotationData.annotation;
        const url = `${process.env.REACT_APP_API_STATEMENTS}`;
        const deleteQuery = `
            DELETE WHERE {
                <${subject}> ?p ?o .
            }
        `;
        const headers = {
            'Content-Type': 'application/sparql-update'
        };
        await axios.post(url, deleteQuery, { headers });
    } catch (err) {
        console.log('Error deleting row');
    }
};

const updateAnnotation = async (annotationData) => {
    try {
        const subject = annotationData.annotation;
        const created = new Date().toISOString();
        const url = `${process.env.REACT_APP_API_STATEMENTS}`;
        const updateQuery = `
            ${Prefixes}
            DELETE {
                <${subject}> dcterms:created ?oldCreated ;
            }
            INSERT {
                <${subject}> dcterms:created "${created}"^^xsd:dateTime .
            }
            WHERE {
                <${subject}> dcterms:created ?oldCreated .
            }
        `;
        const headers = {
            'Content-Type': 'application/sparql-update'
        };
        await axios.post(url, updateQuery, { headers });
    } catch (err) {
        console.log('Error updating annotation');
    }
};


const Annotations: React.FC = () => {
    const [annotations, setAnnotations] = useState([]);
    const [selectedAnnotation, setSelectedAnnotation] = useState(null);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const Request = {
            query: AllAnnotationsQuery,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;
            const annotations = parseAnnotations(bindings);
            setAnnotations(annotations);
        };

        fetch();
    }, []);

    const handleAnnotationChange = (event, value) => {
        setSelectedAnnotation(value);
        setFormData(value);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleUpdate = async () => {
        await updateAnnotation(formData);
        alert('Annotation updated successfully!');
    };

    const handleDelete = async () => {
        await deleteAnnotation(formData);
        alert('Annotation deleted successfully!');
    };

    return (

        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Search by Annotated Text</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Autocomplete
                        options={annotations}
                        getOptionLabel={(option) => option.exact}
                        onChange={handleAnnotationChange}
                        renderInput={(params) => <TextField {...params} label="Annotation (Subject)" variant="outlined" margin="normal" fullWidth />}
                        sx={{ width: '100%' }}
                    />
                    {selectedAnnotation && (
                        <Box sx={{ width: '100%' }}>
                            {Object.entries(formData).map(([key, value]) => (
                                key !== 'annotation' && (
                                    <TextField
                                        key={key}
                                        label={key}
                                        name={key}
                                        value={value || ''}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        onChange={handleInputChange}
                                    />
                                )
                            ))}
                            <Button variant="contained" color="primary" onClick={handleUpdate} style={{ margin: 2 }}>
                                Update Annotation
                            </Button>
                            <Button variant="contained" color="error" onClick={handleDelete}>
                                Delete Annotation
                            </Button>
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>

    );
};

export default Annotations;

