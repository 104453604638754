// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react';
import { Container, Typography } from '@mui/material';

import Annotations from '../api/search/Annotations.tsx';
import Authors from '../api/search/Authors.tsx';
import SourcePassages from '../api/search/SourcePassages.tsx';
import EgotextPassages from '../api/search/EgotextPassages.tsx';
import ResearchPassages from '../api/search/ResearchPassages.tsx';
import Instances from '../api/search/Instances.tsx';
import Works from '../api/search/Work.tsx';
import Persons from '../api/search/Persons.tsx';




const SearchAnnotations: React.FC = () => {


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Annotation Management
            </Typography>
            <Annotations />
            <Authors />
            <SourcePassages />
            <EgotextPassages />
            <ResearchPassages />
            <Instances />
            <Works />
            <Persons />

        </Container>
    );
};

export default SearchAnnotations;

