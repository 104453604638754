// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AllAnnotationsQuery } from '../../data/Queries.ts';
import { FetchData } from '../FetchData.tsx';
import { Link } from 'react-router-dom';


const parseAnnotations = (results) => {
    return results.map((binding, index) => ({
        id: index,
        annotation: binding.annotation.value,
        exact: binding.exact?.value ?? null,
        note: binding.note?.value ?? null,
        author: binding.author?.value,
        authorName: binding.authorName?.value ?? null,
        created: binding.created?.value ?? null,
    }));
};

const deleteAnnotation = async (annotationData) => {
    try {
        const subject = annotationData.annotation;
        const url = `${process.env.REACT_APP_API_STATEMENTS}`;
        const deleteQuery = `
            DELETE WHERE {
                <${subject}> ?p ?o .
            }
        `;
        const headers = {
            'Content-Type': 'application/sparql-update'
        };
        await axios.post(url, deleteQuery, { headers });
    } catch (err) {
        console.log('Error deleting row');
    }
};

const Annotations: React.FC = () => {
    const [annotations, setAnnotations] = useState([]);

    useEffect(() => {
        const Request = {
            query: AllAnnotationsQuery,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;
            const annotations = parseAnnotations(bindings);
            setAnnotations(annotations);
        };

        fetch();
    }, []);

    const handleDelete = async (annotation) => {
        await deleteAnnotation(annotation);
        setAnnotations((prevAnnotations) => prevAnnotations.filter((item) => item.annotation !== annotation.annotation));
        alert('Annotation deleted successfully!');
    };

    const columns = [
        {
            field: 'annotation',
            headerName: 'URI',
            width: 150,
            renderCell: (params) => (
                <Link to={`/viewannotation/${encodeURIComponent(params.value)}`}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'exact', headerName: 'TEXT', width: 150 },
        { field: 'note', headerName: 'NOTE', width: 150, editable: true },
        { field: 'author', headerName: 'ANNOTATOR URI', width: 150 },
        { field: 'authorName', headerName: 'ANNOTATOR', width: 150, editable: true },
        { field: 'created', headerName: 'CREATION DATE', width: 150 },
        {
            field: 'actions',
            headerName: 'ACTIONS',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(params.row)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={annotations}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    );
};

export default Annotations;
