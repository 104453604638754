// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
const Query =
        `SELECT ?annotation ?target ?targetType ?startSelector ?startValue ?endSelector ?endValue ?dataPositionSelector ?startOffset ?endOffset ?source ?textQuoteSelector ?exact ?xmlIDSelector ?startID ?endID ?note ?author ?authorName ?created ?literarysource ?notedin ?egotext ?egotextpart ?instance ?work ?electronicLocator ?sorpages ?identifiedBy ?has_wording ?sorcontext ?resourcepart ?originalLink ?modifiedLink ?sourcepartof
                WHERE {
                ?annotation a oa:Annotation ;
                            oa:hasTarget ?target ;
                            dcterms:created ?created ;
                            oa:hasBody ?body .

                ?target a oa:SpecificResource ;
                        oa:hasSelector ?rangeSelector ;
                        oa:hasSource ?source .

                ?body a intro:INT7_Interpretament ;
                        sor:note ?note ;
                        sor:author ?author ;
                        sor:literarySource ?literarysource ;
                        sor:notedIn ?notedin ;
                        sor:egotext ?egotext .

                ?egotext a sor:EgotextPassage;
                        bf:part ?egotextpart ;
                        bf:partOf ?instance .

                ?instance a bf:Instance ;
                        bf:partOf ?work ;
                        bf:electronicLocator ?electronicLocator;
                        sor:pages ?sorpages ;
                        bf:identifiedBy ?identifiedBy.

                ?literarysource a sor:SourcePassage ;
                        intro:R44_has_wording ?has_wording ;
                        sor:context ?sorcontext ;
                        bf:part ?resourcepart ;
                        sor:originalLink ?originalLink ;
                        sor:modifiedLink ?modifiedLink ;
                        bf:partOf ?sourcepartof.

                ?author foaf:name ?authorName .

                ?rangeSelector a oa:RangeSelector ;
                                oa:hasStartSelector ?startSelector ;
                                oa:hasEndSelector ?endSelector .

                ?startSelector rdf:value ?startValue .
                ?endSelector rdf:value ?endValue .

                OPTIONAL {
                    ?rangeSelector oa:refinedBy ?dataPositionSelector .
                    ?dataPositionSelector a oa:DataPositionSelector ;
                                        oa:start ?startOffset ;
                                        oa:end ?endOffset .
                }

                OPTIONAL {
                    ?rangeSelector oa:refinedBy ?textQuoteSelector .
                    ?textQuoteSelector a oa:TextQuoteSelector ;
                                    oa:exact ?exact .
                }

                OPTIONAL {
                    ?rangeSelector oa:refinedBy ?xmlIDSelector .
                    ?xmlIDSelector a sor:XMLIDSelector ;
                                sor:startID ?startID ;
                                sor:endID ?endID .
                }

                OPTIONAL { ?target oa:hasSource ?source . }
                OPTIONAL { ?target a ?targetType . }
                }`;

export default Query;