// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
const Schema = `@prefix sh: <http://www.w3.org/ns/shacl#> .
@prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
@prefix sorsh: <http://example.org/rosenzweig/annotation_shapes/> .
@prefix sor: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotation#> .
@prefix sorvoc: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotationVocabulary#> .
@prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix roger: <https://www.sub.uni-goettingen.de/roger/schema#> .
@prefix dash: <http://datashapes.org/dash#> .
@prefix skos: <http://www.w3.org/2004/02/skos/core#> .
@prefix dcterms: <http://purl.org/dc/terms#> .
@prefix bf: <http://id.loc.gov/ontologies/bibframe-2-2-0#> .
@prefix oa: <http://www.w3.org/ns/oa#> .
@prefix foaf: <http://xmlns.com/foaf/0.1#> .
@prefix owl: <http://www.w3.org/2002/07/owl#> .
@prefix intro: <https://w3id.org/lso/intro/beta202304#> .

sorsh:shapesGraph1 {

###Web Annotation Model

sorsh:AnnotationShape
  a sh:NodeShape ;
  sh:deactivated false ;
  roger:formNode true ;
  sh:targetClass oa:Annotation ;
  rdfs:label "Create an annotation"@en ;
  rdfs:label "Annotation"@de ;
  rdfs:comment "A shape specifying the class Annotation." ;
  sh:property sorsh:hasBodyShape,
              sorsh:seeAlsoShape.

sorsh:seeAlsoShape  
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class oa:Annotation ;
  sh:message "An annotation can refer to any amount of other annotations with see also."@en ;
  sh:severity sh:Violation ;
  sh:path sor:seeAlso;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:AnnotationShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Gibt es Annotationen zu anderen Textpassagen, die einen Bezug zu dieser haben?"@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Annotations of other passages which are related to this one"@en ;
  sh:name "Siehe auch"@de .

sorsh:hasBodyShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class intro:INT7_Interpretament ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An annotation should have exactly one Interpretament as body."@en ;
  sh:severity sh:Violation ;
  sh:path oa:hasBody;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:InterpretamentShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "Create an annotation"@en ;
  sh:name "Annotation"@de .

###FOAF/Reference Databases

sorsh:hasReferenceDatabaseShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:or (
    [sh:class sor:Wikidata]
    [sh:class sor:GND]
    [sh:class sor:LOC]
    );
  sh:message "A person or work may have any amount of Reference Database entries from Wikidata, the GND or the LOC."@en ;
  sh:severity sh:Violation ;
  sh:path skos:exactMatch;
  sh:or (
    [sh:node sorsh:hasWikidataShape]
    [sh:node sorsh:hasGNDShape]
    [sh:node sorsh:hasLOCShape]
    );
  sh:nodeKind sh:IRI ;
  sh:name "Reference Database"@en ;
  sh:name "Referenzdatenbank"@de .
  
sorsh:hasWikidataShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:Wikidata ;
  sh:message "A person or work may have any amount of Wikidata entries."@en ;
  sh:severity sh:Violation ;
  sh:path skos:exactMatch;
  dash:editor [
  a roger:SelectInstanceFromRemoteDatasetEditor ;
  roger:searchQueryURL "https://www.wikidata.org/w/rest.php/v1/search/page?limit=1&q={searchTerms}" ;
  roger:searchQueryType "application/atom+xml" ;
  ] ;
  sh:node sorsh:WikidataShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Welcher Eintrag in Wikidata repräsentiert diese Person oder dieses Werk?"@de ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Wikidata entry"@en ;
  sh:name "Wikidata"@de .

sorsh:WikidataShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass sor:Wikidata ;
  rdfs:label "Wikidata"@en ;
  rdfs:label "Wikidata"@de ;
  rdfs:comment "A shape specifying the class Wikidata." .

sorsh:hasGNDShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:GND ;
  sh:message "A person or work may have any amount of GND entries."@en ;
  sh:severity sh:Violation ;
  sh:path skos:exactMatch;
  dash:editor [
  a roger:SelectInstanceFromRemoteDatasetEditor ;
  roger:searchQueryURL "" ;
  roger:searchQueryType "application/atom+xml" ;
  ] ;
  sh:node sorsh:GNDShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Welcher Eintrag in der GND repräsentiert diese Person oder dieses Werk?"@de ;
  sh:order "5"^^xsd:decimal ;
  skos:editorialNote "The searchQueryURL needs to be added for this and LOC." ;
  sh:name "Gemeinsame Normdatei (GND)"@en ;
  sh:name "Gemeinsame Normdatei (GND)"@de .

sorsh:GNDShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass sor:GND ;
  rdfs:label "Gemeinsame Normdatei entry"@en ;
  rdfs:label "Gemeinsame Normdatei (GND)"@de ;
  rdfs:comment "A shape specifying the class GND." .

sorsh:hasLOCShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:LOC ;
  sh:message "A person or work may have any amount of LOC entries."@en ;
  sh:severity sh:Violation ;
  sh:path skos:exactMatch;
  dash:editor [
  a roger:SelectInstanceFromRemoteDatasetEditor ;
  roger:searchQueryURL "" ;
  roger:searchQueryType "application/atom+xml" ;
  ] ;
  sh:node sorsh:LOCShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Welcher Eintrag in der LOC repräsentiert diese Person oder dieses Werk?"@de ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Libary of Congress entry"@en ;
  sh:name "Libary of Congress (LOC)"@de .

sorsh:LOCShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass sor:LOC ;
  rdfs:label "Libary of Congress (LOC)"@en ;
  rdfs:label "Libary of Congress (LOC)"@de ;
  rdfs:comment "A shape specifying the class LOC." .

sorsh:PersonShape
  a sh:NodeShape ;
  sh:deactivated true ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass foaf:Person ;
  rdfs:label "Person"@en ;
  rdfs:label "Person"@de ;
  rdfs:comment "A shape specifying the class Person." ;
  sh:property sorsh:nameShape,
              sorsh:hasWikidataShape,
              sorsh:hasGNDShape,
              sorsh:hasLOCShape.

sorsh:nameShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Person should have exactly one name."@en ;
  sh:severity sh:Violation ;
  sh:path foaf:name;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Please enter the name of the person in the format first name last name."@en ;
  sh:description "Bitte geben Sie den Name der Person im Format Vorname Nachname ein."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Insert full name"@en ;
  sh:name "Name"@de .

###Intertextual Relationships

sorsh:InterpretamentShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass intro:INT7_Interpretament ;
  rdfs:label "Interpretament"@en ;
  rdfs:label "Interpretament"@de ;
  rdfs:comment "A shape specifying the class Interpretament." ;
  sh:property sorsh:identifiesShape,
              sorsh:LiterarySourceProposedShape,
              sorsh:authorProposedShape,
              sorsh:notedInShape,
              sorsh:egotextShape,
              sorsh:noteShape.

sorsh:identifiesShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class intro:INT3_IntertextualRelationship ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An interpretament should identify exactly one Intertextual Relationship."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R21_identifies ;
  dash:editor dash:DetailsEditor ;
  sh:node sorsh:IntertextualRelationshipShape ;
  sh:nodeKind sh:BlankNode ;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name ""@de .

sorsh:IntertextualRelationshipShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass intro:INT3_IntertextualRelationship ;
  rdfs:label "Intertextual Relationship"@en ;
  rdfs:label "Intertextuelle Beziehung"@de ;
  rdfs:comment "A shape specifying the class Intertextual Relationship." ;
  sh:property sorsh:hasTypeShape,
              sorsh:hasFormalAppearanceShape.

sorsh:hasTypeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class skos:Concept ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:in (sorvoc:DirectReference sorvoc:Paraphrase sorvoc:CoinedTerm sorvoc:Allusion);
  sh:message "An intertextual relationship should have exactly one reference type."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R19_has_type;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Please select the type of intertextual relation."@en ;
  sh:description "Bitte wählen Sie die Art der intertextuellen Beziehung aus."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Type of reference"@en ;
  sh:name "Art der intertextuellen Beziehung"@de .

sorsh:hasFormalAppearanceShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:FormalAppearance ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation should have exactly one description of formal appearance."@en ;
  sh:severity sh:Violation ;
  sh:path sor:hasFormalAppearance ;
  dash:editor dash:DetailsEditor ;
  sh:node sorsh:FormalAppearanceShape ;
  sh:nodeKind sh:BlankNode ;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Formal Appearance"@en ;
  sh:name "Formale Erscheinung"@de .

sorsh:FormalAppearanceShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass sor:FormalAppearance ;
  rdfs:label "Formal Appearance"@en ;
  rdfs:label "Formale Erscheinung"@de ;
  rdfs:comment "A shape specifying the class Formal Appearance." ;
  sh:property sorsh:hasQuotationMarksShape,
              sorsh:literarySourceStatedShape,
              sorsh:titleStatedShape,
              sorsh:authorStatedShape,
              sorsh:whichAuthorStatedShape.

sorsh:hasQuotationMarksShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:boolean ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "It should be specified exactly once for a formal appearance whether its intertextual relation has quotation marks or not."@en ;
  sh:severity sh:Violation ;
  sh:path sor:hasQuotationMarks;
  dash:editor dash:BooleanSelectEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Ist der annotierte Text in Anführungszeichen?"@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Does the passage have quotation marks?"@en ;
  sh:name "In Anführungszeichen?"@de .

sorsh:titleStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:boolean ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "It should be specified exactly once for a formal appearance whether the source of its intertextual relation has its title stated or not."@en ;
  sh:severity sh:Violation ;
  sh:path sor:titleStated;
  dash:editor dash:BooleanSelectEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Gibt Franz Rosenzweig explizit an, was der Titel des referenzierten Texts ist?"@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "* Is the title of the literary source explicitly stated?"@en ;
  sh:name "Titel angegeben?"@de .

sorsh:literarySourceStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:maxCount 1 ;
  sh:message "A Source Assignment by Franz Rosenzweig should have exactly one Source Passage as source."@en ;
  sh:severity sh:Violation ;
  sh:path sor:literarySource;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourcePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Geben Sie hier bitte den Abschnitt an, der als Quelle der intertextuellen Beziehung angegeben ist. Falls es sich bei der intertextuellen Beziehung um einen Begriff handelt, der nicht einem bestimmten Werk zugewiesen werden kann (Art 'Technical Term'), dient der Abschnitt, denn Sie hier angegeben, als repräsentatives Beispiel."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Explicitly stated literary source"@en ;
  sh:name "Angegebene Quelle"@de .

sorsh:authorStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:boolean ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "It should be specified exactly once for a formal appearance whether the source of its intertextual relation has its author stated or not."@en ;
  sh:severity sh:Violation ;
  sh:path sor:authorStated;
  dash:editor dash:BooleanSelectEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Bitte wählen Sie 'true' aus, falls Franz Rosenzweig explizit angegeben hat, wer der/die AutorIn des referenzierten Texts ist, andernfalls 'false'."@de ;
  sh:order "4"^^xsd:decimal ;
  sh:name "* Is the author explicitly stated?"@en ;
  sh:name "AutorIn angegeben?"@de .

sorsh:whichAuthorStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation can have at most one person stated as the author."@en ;
  sh:severity sh:Violation ;
  sh:path sor:author;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape;
  sh:nodeKind sh:IRI ;
  sh:description "Who is stated as the author of the reference text?"@en ;
  sh:description "Wer ist als AutorIn der intertextuellen Beziehung genannt?"@de ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Explicitly stated author"@en ;
  sh:name "Angegebene/r AutorIn"@de .

sorsh:LiterarySourceProposedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:maxCount 1 ;
  sh:message "A Source Assignment by the annotator should have exactly one Source Passage as source."@en ;
  sh:severity sh:Violation ;
  sh:path sor:literarySource;
  dash:editor dash:AutoCompleteEditor ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Geben Sie hier bitte den Abschnitt an, den Sie als Quelle der intertextuellen Beziehung vorschlagen möchten. Falls es sich bei der intertextuellen Beziehung um einen Begriff handelt, der nicht einem bestimmten Werk zugewiesen werden kann (Art 'Technical Term'), dient der Abschnitt, denn Sie hier angegeben, als repräsentatives Beispiel."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Suggest a not explicitly stated literary source"@en ;
  sh:name "Quelle"@de .

sorsh:authorProposedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation can have at most one person proposed as the author"@en ;
  sh:severity sh:Violation ;
  sh:path sor:author;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Wen möchten Sie als AutorIn der intertextuellen Beziehung vorschlagen?"@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Suggest an alternative to an explicitly stated author"@en ;
  sh:name "AutorIn"@de .

sorsh:notedInShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:ResearchLiteraturePassage ;
  sh:message "An Interpretament can have any amount of Research Literature Passages in which its content was noted in."@en ;
  sh:severity sh:Violation ;
  sh:path sor:notedIn;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ResearchLiteraturePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Research literature which talks about this intertextual relation."@en ;
  sh:description "Gibt es Forschungsliteratur, die die hier beschriebene intertextuelle Beziehung vorgeschlagen (oder dementiert) hat?"@de ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Reference to research literature related to this intertextual relation"@en ;
  sh:name "Forschungsliteratur"@de .

sorsh:egotextShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:EgotextPassage ;
  sh:message "An Interpretament can have any amount of Egotext Passages which are related to it."@en ;
  sh:severity sh:Violation ;
  sh:path sor:egotext;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:EgotextPassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Any writing by Franz Rosenzweig which relates to this intertextual relation."@en ;
  sh:description "Ein Abschnitt aus Franz Rosenzweigs eigenen Schriften, einschließlich seiner Briefe und Tagebücher, der für die hier beschriebene intertextuelle Beziehung relevant ist."@de ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Reference to Franz Rosenzweig's letters, diaries, published works or archival material related to this intertextual relation"@en ;
  sh:name "Egotext"@de .

sorsh:noteShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation should have at most one note."@en ;
  sh:severity sh:Violation ;
  sh:path sor:note;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "An arbitrary note containing (any) additional information. This is a 'catch-it-all' solution to record information that does not fit anywhere else."@en ;
  sh:description "An arbitrary note containing (any) additional information. This is a 'catch-it-all' solution to record information that does not fit anywhere else."@de ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Provide justification or additional information about this intertextual relation"@en ;
  sh:name "Notiz"@de .

###Passages

sorsh:SourcePassageShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass sor:SourcePassage ;
  rdfs:label "Refer to a primary source"@en ;
  rdfs:label "Quell-Abschnitt"@de ;
  rdfs:comment "A shape specifying the class Source Passage." ;
  sh:property sorsh:sourceHasWordingShape,
              sorsh:contextShape,
              sorsh:sourceOriginalLinkShape,
              sorsh:sourceModifiedLinkShape,
              sorsh:passagePartOfShape,
              sorsh:sourcePartShape,
              sorsh:sourceEquivalentPassageShape.

sorsh:ResearchLiteraturePassageShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass sor:ResearchLiteraturePassage ;
  rdfs:label "Refer to secondary research literature"@en ;
  rdfs:label "Forschungsliteratur-Abschnitt"@de ;
  rdfs:comment "A shape specifying the class Research Literature Passage." ;
  sh:property sorsh:researchModifiedLinkShape,
              sorsh:researchOriginalLinkShape,
              sorsh:passagePartOfShape,
              sorsh:partShape,
              sorsh:researchEquivalentPassageShape.
              
sorsh:EgotextPassageShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass sor:EgotextPassage ;
  rdfs:label "Refer to egotexts"@en ;
  rdfs:label "Egotext-Abschnitt"@de ;
  rdfs:comment "A shape specifying the class Egotext Passage." ;
  sh:property sorsh:egotextHasWordingShape,
              sorsh:egotextOriginalLinkShape,
              sorsh:egotextModifiedLinkShape,
              sorsh:passagePartOfShape,
              sorsh:partShape,
              sorsh:egotextEquivalentPassageShape.

sorsh:sourceHasWordingShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Literary Source or Egotext Passage should have exactly one wording."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R44_has_wording;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Geben Sie hier bitte den Text des referenzierten Abschnitts an, wie er in der Quelle zu finden ist."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Insert quotation as written in the source"@en ;
  sh:name "Referenzierter Text"@de .

sorsh:egotextHasWordingShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A Literary Source or Egotext Passage should have exactly one wording."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R44_has_wording;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Please provide here the text constituting the referenced passage as found in the source."@en ;
  sh:description "Geben Sie hier bitte den Text des referenzierten Abschnitts an, wie er in der Quelle zu finden ist."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "Text referenced"@en ;
  sh:name "Referenzierter Text"@de .

sorsh:contextShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Source Passage should have exactly one context."@en ;
  sh:severity sh:Violation ;
  sh:path sor:context;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Insert the full relevant sentence or paragraph in the cited source."@en ;
  sh:description "Geben Sie hier bitte den größeren Kontext der referenzierte Abschnitt in der Quelle an, um den direkten Vergleich zu ermöglichen. Als Richtwert: Höchstens ein Absatz, aber mindestens der ganze Satz, in welchem sich der Abschnitt befindet."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "* Insert larger context of the quotation in the source"@en ;
  sh:name "Kontext"@de .

sorsh:sourceOriginalLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:maxCount 1 ;
  sh:message "A Passage should have at most one preferred link."@en ;
  sh:severity sh:Violation ;
  sh:path sor:originalLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description "Insert the URL of a website hosting the source text. This link should lead to the specific passage (rather than to the fulltext) and be in an edition close to what Franz Rosenzweig likely used."@en ;
  sh:description "Geben Sie hier bite eine URL zu einem Onlinetext an, welche direkt zu diesem Abschnitt führt, in einer Version, die möglichst nah an der von Rosenzweig wahrscheinlich verwendeten ist (gleiche Sprache, nahes Veröffentlichungsdatum)."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Link to online edition"@en ;
  sh:name "Link zur Onlineausgabe"@de .
  
sorsh:researchOriginalLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:maxCount 1 ;
  sh:message "A Passage should have at most one preferred link."@en ;
  sh:severity sh:Violation ;
  sh:path sor:originalLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:description "Geben Sie hier bite eine URL zu einem Onlinetext an, welche direkt zu diesem Abschnitt führt, in einer Version, die möglichst nah an der von Rosenzweig wahrscheinlich verwendeten ist (gleiche Sprache, nahes Veröffentlichungsdatum)."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Link to website displaying the section of the research literature"@en ;
  sh:name "Link zur Onlineausgabe"@de .
  
sorsh:egotextOriginalLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:maxCount 1 ;
  sh:message "A Passage should have at most one preferred link."@en ;
  sh:severity sh:Violation ;
  sh:path sor:originalLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:description "Geben Sie hier bite eine URL zu einem Onlinetext an, welche direkt zu diesem Abschnitt führt, in einer Version, die möglichst nah an der von Rosenzweig wahrscheinlich verwendeten ist (gleiche Sprache, nahes Veröffentlichungsdatum)."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Link to website displaying the passage"@en ;
  sh:name "Link zur Onlineausgabe"@de .

sorsh:sourceModifiedLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A Passage can have zero or more direct links."@en ;
  sh:severity sh:Violation ;
  sh:path sor:modifiedLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description "Insert the URL of a website hosting the source text. This link should lead to the specific passage (rather than to the fulltext) and be in an edition differing from what Franz Rosenzweig likely used (e.g. a translation or re-edition)."@en ;
  sh:description "Geben Sie hier bite eine URL zu einem Onlinetext an, welche direkt zu diesem Abschnitt führt, in einer Version, die sich von der hier referenzierten Ausgabe unterscheidet, jedoch nutzerfreundlicher ist (z.B. eine mehrsprachige Ausgabe)."@de ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Link to website displaying the passage in different version"@en ;
  sh:name "Link mit modifiziertem Text"@de .
  
sorsh:researchModifiedLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A Passage can have zero or more direct links."@en ;
  sh:severity sh:Violation ;
  sh:path sor:modifiedLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:description "Geben Sie hier bite eine URL zu einem Onlinetext an, welche direkt zu diesem Abschnitt führt, in einer Version, die sich von der hier referenzierten Ausgabe unterscheidet, jedoch nutzerfreundlicher ist (z.B. eine mehrsprachige Ausgabe)."@de ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Link to website displaying the section of the research literature in translation."@en ;
  sh:name "Link mit modifiziertem Text"@de .
  
sorsh:egotextModifiedLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A Passage can have zero or more direct links."@en ;
  sh:severity sh:Violation ;
  sh:path sor:modifiedLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:description "Geben Sie hier bite eine URL zu einem Onlinetext an, welche direkt zu diesem Abschnitt führt, in einer Version, die sich von der hier referenzierten Ausgabe unterscheidet, jedoch nutzerfreundlicher ist (z.B. eine mehrsprachige Ausgabe)."@de ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Link to website displaying the passage in translation"@en ;
  sh:name "Link mit modifiziertem Text"@de .

sorsh:passagePartOfShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Instance ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should be part of exactly one Instance."@en ;
  sh:severity sh:Violation ;
  sh:path bf:partOf;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:InstanceShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Die Ausgabe, in welcher der Abschnitt zu finden ist."@de ;
  sh:order "5"^^xsd:decimal ;
  sh:name "* Edition you used"@en ;
  sh:name "Ausgabe"@de .

sorsh:instancePartOfShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Instance ;
  sh:maxCount 1 ;
  sh:message "An Instance should be part of at most one Instance."@en ;
  sh:severity sh:Violation ;
  sh:path bf:partOf;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:InstanceShape ;
  sh:nodeKind sh:IRI ;
  sh:description "If this is an article or book chapter which is part of another work, link it here to the edition of that work."@en ;
  sh:description "Die Ausgabe, in welcher der Abschnitt zu finden ist."@de ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Name of journal, collection or collected works"@en ;
  sh:name "Ausgabe"@de .

sorsh:sourcePartShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should correspond to exactly one part."@en ;
  sh:severity sh:Violation ;
  sh:path bf:part;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Die Seitenzahl, Versnummer etc. des Abschnitts."@de ;
  sh:order "6"^^xsd:decimal ;
  sh:name "* Insert page number or verse number of the passage"@en ;
  sh:name "Seitenzahl/Versnummer"@de .
  
sorsh:partShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should correspond to exactly one part."@en ;
  sh:severity sh:Violation ;
  sh:path bf:part;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Die Seitenzahl, Versnummer etc. des Abschnitts."@de ;
  sh:order "6"^^xsd:decimal ;
  sh:name "* Insert relevant page numbers"@en ;
  sh:name "Seitenzahl/Versnummer"@de .

sorsh:sourceEquivalentPassageShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:message "A Passage can have any amount of equivalent passages."@en ;
  sh:severity sh:Violation ;
  sh:path sor:equivalentPassage;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourcePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Link this passage to other passages in the database which refer to the same text."@en ;
  sh:description "Andere Abschnitte, die zu dieser äquivalent sind (e.g. aus anderen Ausgaben)."@de ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Equivalent other passages from literary works"@en ;
  sh:name "Äquivalente Abschnitte"@de .
  
sorsh:researchEquivalentPassageShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:message "A Passage can have any amount of equivalent passages."@en ;
  sh:severity sh:Violation ;
  sh:path sor:equivalentPassage;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourcePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Link this passage to other passages in the database which refer to the same text."@en ;
  sh:description "Andere Abschnitte, die zu dieser äquivalent sind (e.g. aus anderen Ausgaben)."@de ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Equivalent other passages from research literature"@en ;
  sh:name "Äquivalente Abschnitte"@de .
  
sorsh:egotextEquivalentPassageShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:message "A Passage can have any amount of equivalent passages."@en ;
  sh:severity sh:Violation ;
  sh:path sor:equivalentPassage;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourcePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Link this passage to other passages in the database which refer to the same text."@en ;
  sh:description "Andere Abschnitte, die zu dieser äquivalent sind (e.g. aus anderen Ausgaben)."@de ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Equivalent other passages from Rosenzweig's egotexts"@en ;
  sh:name "Äquivalente Abschnitte"@de .

###Bibliographic Framework

#InstanceShape has a version for validation (the other version is deactivated for that) because from there, there is a different indirect path each to bf:role, so that it can be validated that they each have only those roles that are specific to instance  contributions and work contributions.

sorsh:InstanceShape
  a sh:NodeShape ;
  sh:deactivated true ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass bf:Instance ;
  rdfs:label "Add a new book, letter or article"@en ;
  rdfs:comment "The edition/version which a passage is found in." ;
  sh:property sorsh:titleShape,
              sorsh:instanceOfShape,
              sorsh:instanceContributionShape,
              sorsh:instancePartOfShape,
              sorsh:pagesShape,
              sorsh:electronicLocatorShape,
              sorsh:provisionActivityShape,
              sorsh:identifiedByShape,
              sorsh:dissertationShape.

sorsh:instanceTitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Title ;
  sh:maxCount 1 ;
  sh:message "A work or instance should have at most one title."@en ;
  sh:severity sh:Violation ;
  sh:path bf:title;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:TitleShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name ""@de .

sorsh:InstanceTitleShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Title ;
  rdfs:label "Title"@en ;
  rdfs:label "Titel"@de ;
  rdfs:comment "A shape specifying the class Title." ;
  sh:property sorsh:instanceMainTitleShape,
              sorsh:instanceSubtitleShape.

sorsh:instanceMainTitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A work or instance should have exactly one main title."@en ;
  sh:severity sh:Violation ;
  sh:path bf:mainTitle;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier den Haupttitel an."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Insert (edition-specific) main title"@en ;
  sh:name "Haupttitel"@de .

sorsh:instanceSubtitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A work or instance should have at most one subtitle."@en ;
  sh:severity sh:Violation ;
  sh:path bf:subtitle;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier den Untertitel an."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Insert (edition-specific) subtitle"@en ;
  sh:name "Untertitel"@de .

sorsh:workTitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Title ;
  sh:maxCount 1 ;
  sh:message "A work or instance should have at most one title."@en ;
  sh:severity sh:Violation ;
  sh:path bf:title;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:TitleShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name ""@de .

sorsh:WorkTitleShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Title ;
  rdfs:label "Title"@en ;
  rdfs:label "Titel"@de ;
  rdfs:comment "A shape specifying the class Title." ;
  sh:property sorsh:workMinTitleShape,
              sorsh:workSubtitleShape.

sorsh:workMainTitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A work or instance should have exactly one main title."@en ;
  sh:severity sh:Violation ;
  sh:path bf:mainTitle;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier den Haupttitel an."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Insert main title"@en ;
  sh:name "Haupttitel"@de .

sorsh:workSubtitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A work or instance should have at most one subtitle."@en ;
  sh:severity sh:Violation ;
  sh:path bf:subtitle;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier den Untertitel an."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Insert subtitle"@en ;
  sh:name "Untertitel"@de .

sorsh:instanceOfShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Work ;
  sh:maxCount 1 ;
  sh:message "An Instance should be an instance of at most one Work."@en ;
  sh:severity sh:Violation ;
  sh:path bf:instanceOf;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:WorkShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Das Werk, wovon dies eine Edition ist."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name "Ausgabe von"@de .
  
sorsh:instanceContributionShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Contribution ;
  sh:message "An instance can have any amount of contributions."@en ;
  sh:severity sh:Violation ;
  sh:path bf:contribution;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:InstanceContributionShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name "Beitrag"@de .

sorsh:InstanceContributionShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Contribution ;
  rdfs:label "Contribution"@en ;
  rdfs:label "Beitrag"@de ;
  rdfs:comment "A shape specifying the class Contribution." ;
  sh:property sorsh:editorInstanceRoleShape,
              sorsh:editorContributionAgentShape,
              sorsh:translatorInstanceRoleShape,
              sorsh:translatorContributionAgentShape.

sorsh:editorInstanceRoleShape
  a sh:PropertyShape ;
  sh:deactivated true ;
  sh:class skos:Concept ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:in (sorvoc:Editor) ;
  sh:defaultValue sorvoc:Editor ;
  sh:message "A contribution should have exactly one contribution role. For Instances, it should be editor or translator."@en ;
  sh:severity sh:Violation ;
  sh:path bf:role;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie an, ob die Person der/die Editor/In oder ÜbersetzerIn der Ausgabe ist."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name "EditorIn oder ÜbersetzerIn"@de .
  
  sorsh:translatorInstanceRoleShape
  a sh:PropertyShape ;
  sh:deactivated true ;
  sh:class skos:Concept ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:in (sorvoc:Translator) ;
  sh:defaultValue sorvoc:Translator ;
  sh:message "A contribution should have exactly one contribution role. For Instances, it should be editor or translator."@en ;
  sh:severity sh:Violation ;
  sh:path bf:role;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie an, ob die Person der/die Editor/In oder ÜbersetzerIn der Ausgabe ist."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name "EditorIn oder ÜbersetzerIn"@de .

sorsh:ConceptShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass skos:Concept ;
  rdfs:label "Concept"@en ;
  rdfs:label "Konzept"@de ;
  rdfs:comment "A shape specifying the class Concept.".

sorsh:editorContributionAgentShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A contribution should have exactly one person as agent."@en ;
  sh:severity sh:Violation ;
  sh:path bf:agent ;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape ;
  sh:nodeKind sh:IRI;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier die Person an."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Editor"@en ;
  sh:name "Beitragende/r"@de .
  
sorsh:translatorContributionAgentShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A contribution should have exactly one person as agent."@en ;
  sh:severity sh:Violation ;
  sh:path bf:agent ;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape ;
  sh:nodeKind sh:IRI;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier die Person an."@de ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Translator"@en ;
  sh:name "Beitragende/r"@de .  
  
sorsh:authorContributionAgentShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A contribution should have exactly one person as agent."@en ;
  sh:severity sh:Violation ;
  sh:path bf:agent ;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape ;
  sh:nodeKind sh:IRI;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier die Person an."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Author"@en ;
  sh:name "Beitragende/r"@de .  
  
sorsh:recipientContributionAgentShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A contribution should have exactly one person as agent."@en ;
  sh:severity sh:Violation ;
  sh:path bf:agent ;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape ;
  sh:nodeKind sh:IRI;
  sh:description ""@en ;
  sh:description "Bitte geben Sie hier die Person an."@de ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Recipient"@en ;
  sh:name "Beitragende/r"@de .

sorsh:pagesShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one specification of its page range."@en ;
  sh:severity sh:Violation ;
  sh:path sor:pages;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "If this is an article or book chapter which is part of another work, specify here on which pages it is found."@en ;
  sh:description "Falls es sich um ein Kapitel in einem Buch oder einen Artikel in einer Zeitschriftenausgabe handet, geben Sie hier bitte die Seiten an. "@de ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Insert page numbers"@en ;
  sh:name "Seiten"@de .

sorsh:electronicLocatorShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "An instance can have any amount of electronic locators."@en ;
  sh:severity sh:Violation ;
  sh:path bf:electronicLocator ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:description "Bitte geben Sie eine URL an, unter der ein Volltext der Ausgabe gefunden werden kann."@de ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Insert the URL of a website hosting the full text of the edition"@en ;
  sh:name "Volltext online"@de .

sorsh:provisionActivityShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Publication ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one provision activity."@en ;
  sh:severity sh:Violation ;
  sh:path bf:provisionActivity;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:PublicationShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "8"^^xsd:decimal ;
  sh:name "Publisher and Place"@en ;
  sh:name "Verlag und Ort"@de .

sorsh:PublicationShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Publication ;
  rdfs:label ""@en ;
  rdfs:label "Veröffentlichung"@de ;
  rdfs:comment "A shape specifying the class Publication." ;
  sh:property sorsh:publisherAgentShape,
              sorsh:placeShape,
              sorsh:dateShape.

sorsh:publisherAgentShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:Publisher ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A provision activity should have exactly one publisher as agent."@en ;
  sh:severity sh:Violation ;
  sh:path bf:agent;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PublisherShape ;
  sh:nodeKind sh:IRI;
  sh:description "Publisher."@en ;
  sh:description "Bitte nennen Sie den Verlag."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "Publisher"@en ;
  sh:name "Verlag"@de .

sorsh:PublisherShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass sor:Publisher ;
  rdfs:label "Publisher"@en ;
  rdfs:label "Verlag"@de ;
  rdfs:comment "A shape specifying the class Publisher." ;
  sh:property sorsh:nameShape.

sorsh:placeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Place ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A provision activity should have exactly one place."@en ;
  sh:severity sh:Violation ;
  sh:path bf:place ;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PlaceShape ;
  sh:nodeKind sh:IRI;
  sh:description "Place of publication"@en ;
  sh:description "Bitte geben sie deb Veröffentlichungsort an."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Place of publication"@en ;
  sh:name "Ort der Veröffentlichung"@de .

sorsh:PlaceShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Place ;
  rdfs:label "Place of publication"@en ;
  rdfs:label "Ort der Veröffentlichung"@de ;
  rdfs:comment "A shape specifying the class Place." ;
  sh:property sorsh:nameShape .
  
sorsh:dateShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A provision activity should have at most one date."@en ;
  sh:severity sh:Violation ;
  sh:path bf:date;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Bitte geben sie das Veröffentlichungsjahr an."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Insert date of publication"@en ;
  sh:name "Jahr der Veröffentlichung."@de .

sorsh:identifiedByShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Isbn ;
  sh:maxCount 1 ;
  sh:message "An instance can have at most one ISBN."@en ;
  sh:severity sh:Violation ;
  sh:path bf:identifiedBy ;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:IsbnShape ;
  sh:nodeKind sh:IRI;
  sh:description ""@en ;
  sh:description "Bitte geben Sie falls vorhanden die ISBN an."@de ;
  sh:order "9"^^xsd:decimal ;
  sh:name "ISBN"@en ;
  sh:name "ISBN"@de .

sorsh:IsbnShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Isbn ;
  rdfs:label "ISBN"@en ;
  rdfs:label "ISBN"@de ;
  rdfs:comment "A shape specifying the class Isbn." .

sorsh:dissertationShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Dissertation ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one dissertation statement."@en ;
  sh:severity sh:Violation ;
  sh:path bf:dissertation;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:DissertationShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "10"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name ""@de .

sorsh:DissertationShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Dissertation ;
  rdfs:label ""@en ;
  rdfs:label ""@de ;
  rdfs:comment "A shape specifying the class Dissertation." ;
  sh:property sorsh:degreeShape,
              sorsh:grantingInstitutionShape.

sorsh:degreeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A dissertation should have exactly one degree."@en ;
  sh:severity sh:Violation ;
  sh:path bf:degree;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:description "Der Abschluss, für den die Abschlussarbeit eingereicht wurde."@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "If this is a university thesis, write here for which degree it was submitted"@en ;
  sh:name "Abschluss"@de .

sorsh:grantingInstitutionShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:GrantingInstitution ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A dissertation should have exactly one granting institution."@en ;
  sh:severity sh:Violation ;
  sh:path bf:grantingInstitution;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:GrantingInstitutionShape ;
  sh:nodeKind sh:IRI;
  sh:description ""@en ;
  sh:description "Die Institution, bei der die Abschlussarbeit eingereicht wurde."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name "If this is a university thesis, to which institution was it submitted?"@en ;
  sh:name "Verleihende Institution"@de .

sorsh:GrantingInstitutionShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass sor:GrantingInstitution ;
  rdfs:label "Granting Institution"@en ;
  rdfs:label "Vergebende Institution"@de ;
  rdfs:comment "A shape specifying the class Granting Institution." ;
  sh:property sorsh:nameShape.

sorsh:WorkShape
  a sh:NodeShape ;
  sh:deactivated true ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass bf:Work ;
  rdfs:label "Work"@en ;
  rdfs:label "Werk"@de ;
  rdfs:comment "A shape specifying the class Work." ;
  sh:property sorsh:workTitleShape,
              sorsh:workContributionShape,
              sorsh:originDateShape,
              sorsh:hasWikidataShape,
              sorsh:hasLOCShape,
              sorsh:hasGNDShape.

sorsh:workContributionShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Contribution ;
  sh:message "A work can have any amount of contributions."@en ;
  sh:severity sh:Violation ;
  sh:path bf:contribution;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:WorkContributionShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:description ""@de ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Contribution"@en ;
  sh:name "Beitrag"@de .

sorsh:WorkContributionShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Contribution ;
  rdfs:label "Contribution"@en ;
  rdfs:label "Beitrag"@de ;
  rdfs:comment "A shape specifying the class Contribution." ;
  sh:property sorsh:authorWorkRoleShape,
              sorsh:authorContributionAgentShape,
              sorsh:recipientWorkRoleShape,
              sorsh:recipientContributorAgentShape.

sorsh:authorWorkRoleShape
  a sh:PropertyShape ;
  sh:deactivated true ;
  sh:class skos:Concept ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:in (sorvoc:Author sorvoc:Recipient) ;
  sh:defaultValue sorvoc:Author ;
  sh:message "A contribution should have exactly one contribution role. For works, it should be author or recipient."@en ;
  skos:editorialNote "This could be split into two shapes, set to author or recipient, respectively. Same for the instanceRoleShape." ;
  sh:severity sh:Violation ;
  sh:path bf:role;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie an, ob die Person der/die AutorIn oder EmpfängerIn des Werks ist."@de ;
  sh:order "1"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name "AutorIn oder EmpfängerIn?"@de .
  
sorsh:recipientWorkRoleShape
  a sh:PropertyShape ;
  sh:deactivated true ;
  sh:class skos:Concept ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:in (sorvoc:Author sorvoc:Recipient) ;
  sh:defaultValue sorvoc:Author ;
  sh:message "A contribution should have exactly one contribution role. For works, it should be author or recipient."@en ;
  skos:editorialNote "This could be split into two shapes, set to author or recipient, respectively. Same for the instanceRoleShape." ;
  sh:severity sh:Violation ;
  sh:path bf:role;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:description "Bitte geben Sie an, ob die Person der/die AutorIn oder EmpfängerIn des Werks ist."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name ""@en ;
  sh:name "AutorIn oder EmpfängerIn?"@de .

sorsh:originDateShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A work should have at most one date."@en ;
  sh:severity sh:Violation ;
  sh:path bf:originDate;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "The year in which the work was created. Please use either the format YYYY-MM-DD or YYYY-MM or YYYY."@en ;
  sh:description "Das Jahr, in welchem das Werk entstanden ist. Bitte verwenden Sie das Format YYYY-MM-DD oder YYYY-MM oder YYYY."@de ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Insert original date of creation"@en ;
  sh:name "Entstehungsdatum"@de .
 
 sor:dataGraph sh:shapesGraph sorsh:shapesGraph1.
}`;

export default Schema;