// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ResearchPassages as ResearchPassagesQuery } from '../../data/Queries.ts';
import { FetchData } from '../FetchData.tsx';

const parseResearchPassages = (results) => {
    return results.map((binding, index) => ({
        id: index,
        researchpassage: binding.researchliteraturepassage.value,
        haswording: binding.haswording?.value ?? null,
        name: binding.name?.value ?? null,
        workmaintitle: binding.workmaintitle?.value,
        worksubtitle: binding.worksubtitle?.value ?? null,
        name2: binding.name2?.value ?? null,
        workmaintitle2: binding.workmaintitle2?.value ?? null,
        worksubtitle2: binding.worksubtitle2?.value ?? null,
        instancemaintitle: binding.instancemaintitle?.value ?? null,
        instancesubtitle: binding.instancesubtitle?.value ?? null,
        instancemaintitle2: binding.instancemaintitle2?.value ?? null,
        instancesubtitle2: binding.instancesubtitle2?.value ?? null,
    }));
};

const deleteResearchPassage = async (researchPassageData) => {
    try {
        const subject = researchPassageData.researchpassage;
        const url = `${process.env.REACT_APP_API_STATEMENTS}`;
        const deleteQuery = `
            DELETE WHERE {
                <${subject}> ?p ?o .
            }
        `;
        const headers = {
            'Content-Type': 'application/sparql-update'
        };
        await axios.post(url, deleteQuery, { headers });
    } catch (err) {
        console.log('Error deleting row');
    }
}

const ResearchPassages: React.FC = () => {
    const [researchPassages, setResearchPassages] = useState([]);

    useEffect(() => {
        const Request = {
            query: ResearchPassagesQuery,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;
            const researchPassages = parseResearchPassages(bindings);
            setResearchPassages(researchPassages);
        };

        fetch();
    }, []);


    const handleDelete = async (researchPassage) => {
        await deleteResearchPassage(researchPassage);
        setResearchPassages((prevResearchPassages) => prevResearchPassages.filter((item) => item.researchpassage !== researchPassage.researchpassage));
        alert('Source Passage deleted successfully!');
    };

    const columns = [
        { field: 'instancemaintitle', headerName: 'Instance Main Title', width: 200 },
        { field: 'workmaintitle', headerName: 'Work Main Title', width: 200 },
        { field: 'worksubtitle', headerName: 'Work Subtitle', width: 200 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'haswording', headerName: 'Has Wording', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(params.row)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid rows={researchPassages} columns={columns} initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 5,
                    },
                },
            }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick />
        </Box>
    );
};

export default ResearchPassages;
