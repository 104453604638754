// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

// src/components/Edit.tsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography } from '@mui/material';
import Prefixes from '../data/Prefixes.ts';

// Prefix mapping
const prefixMapping = {

    instance: 'sor:instance',
    electronicLocator: 'sor:electronicLocator',
    egotext: 'sor:egotext',
    literarysource: 'sor:literarysource',
    annotation: 'sor:annotation',
    target: 'sor:target',
    sorpages: 'sor:sorpages',
    identifiedBy: 'sor:identifiedBy',
    has_wording: 'sor:has_wording',
    sorcontext: 'sor:sorcontext',
    originalLink: 'sor:originalLink',
    modifiedLink: 'sor:modifiedLink',
    startSelector: 'sor:startSelector',
    endSelector: 'sor:endSelector',
    egotextpart: 'sor:egotextpart',
    resourcepart: 'sor:resourcepart',
    work: 'sor:work',
    sourcepartof: 'sor:sourcepartof',
    author: 'sor:author',
    authorName: 'sor:authorName',
    created: 'sor:created',
    source: 'sor:source',
    note: 'sor:note',
    notedin: 'sor:notedin',
    startValue: 'sor:startValue',
    endValue: 'sor:endValue',
    dataPositionSelector: 'sor:dataPositionSelector',
    startOffset: 'sor:startOffset',
    endOffset: 'sor:endOffset',
    textQuoteSelector: 'sor:textQuoteSelector',
    exact: 'sor:exact',
    xmlIDSelector: 'sor:xmlIDSelector',
    startID: 'sor:startID',
    endID: 'sor:endID',
    targetType: 'sor:targetType',
    name: '<foaf:name>',
    subject: '<rdf:instance>',
};

const Edit: React.FC = () => {
    const location = useLocation();
    const { row } = location.state;

    // Check the initial data
    useEffect(() => {
        console.log('Initial row data:', row);
    }, [row]);

    const [formData, setFormData] = useState(row);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            // Use annotation as the subject
            const subject = formData.subject;
            console.log('Form Data:', formData);
            //console.log('Subject:', subject);

            if (!subject) {
                alert('Subject is not defined');
                return;
            }

            // Construct SPARQL UPDATE query
            const updateQuery = `
        ${Prefixes}

        DELETE {
          <${subject}> ?predicate ?object.
        }
        INSERT {
          ${Object.keys(formData).map((key) => {
                if (key !== 'annotation') {
                    const predicate = prefixMapping[key] || key;
                    return `<${subject}> ${predicate} "${formData[key]}" .`;
                }
                return '';
            }).join('\n')}
        }
        WHERE {
          <${subject}> ?predicate ?object.
        }
      `;

            console.log('SPARQL Update Query:', updateQuery);
            const url = `${process.env.REACT_APP_API_STATEMENTS}`;

            await axios.post(
                url,
                updateQuery,
                {
                    headers: {
                        'Content-Type': 'application/sparql-update'
                    }
                }
            );
            alert('Data updated successfully');
        } catch (err) {
            console.error(err);
            alert('Error updating data');
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Edit Row
            </Typography>
            {Object.keys(formData).map((key) => (
                <TextField
                    key={key}
                    label={key}
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            ))}
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Save Changes
            </Button>
        </Container>
    );
};

export default Edit;
