// SPDX-FileCopyrightText: 2022 CodedThemes (Mantis Theme)
//
// SPDX-License-Identifier: MIT

// ==============================|| OVERRIDES - LINER PROGRESS ||============================== //

export default function LinearProgress() {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 6,
          borderRadius: 100
        },
        bar: {
          borderRadius: 100
        }
      }
    }
  };
}
