// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import type { Quad } from "@rdfjs/types";

import * as RST from "rdf-string-ttl";
import axios from "axios";
//import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

export interface SparqlConfig {
  sparqlEndpoint: string;
  sparqlUpdateEndpoint: string;
}

/**
 * Update a resource, that is an array of Quads with the same subject, in a
 * triple store with SPARQL.
 *
 * @param quads
 */
export async function updateResource(quads: Quad[], config: SparqlConfig) {
  if (quads.length) {
    console.log("final", quads);
    // convert the rdfjs typed quads to stringified quads
    const stringifiedQuads: RST.IStringQuad[] = [];
    for (const q of quads) stringifiedQuads.push(RST.quadToStringQuad(q));

    // build the query from the stringified quads
    //let query = `DELETE { ${stringifiedQuads[0].subject} ?p ?o }\n
    let query = '';
    //INSERT {\n`;

    //let query = `INSERT {\n`;

    for (const sq of stringifiedQuads)
      query += `${sq.subject}  ${sq.predicate} ${sq.object} .\n`;
    /*
    for (const sq of stringifiedBackQuads)
      query += `${sq.subject}  ${sq.predicate} ${sq.object} .\n`;
    */
    //query += `}\n
    //WHERE { ?s ?p ?o }`;

    //console.log(query);


    // execute the query against a sparql-update endpoint
    try {
      await axios.post(config.sparqlUpdateEndpoint, query, {
        headers: { "content-type": "text/turtle" },
      });
      //toast.success("Annotation added successfully");
      alert("Annotation added successfully");
    } catch (error) {
      console.log(error); // TODO: handle messaging...
    }

  }
}
