// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: CC0-1.0

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { parsePromise } from "@fe/roger-core";
import * as N3 from "n3";
import "./i18n";
import Schema from "./data/Schema.ts";
import ThemeCustomization from './themes/index.jsx';
import ScrollTop from './components/ScrollTop.jsx';
import router from './routes';
import { RouterProvider } from 'react-router-dom';
import './assets/css/custom-app.css';




const parse = async (input) => {
  const store = new N3.Store();
  const parser = new N3.Parser();
  return await parsePromise(parser, input, store);
};

/** The preview app's props are reduced to "schema", as data is supposed to be injected by the Frames of this library. */
function App({ schema }) {
  const [schemaStore, setSchemaStore] = React.useState();
  useEffect(() => {
    (async () => {
      let result;
      try {
        result = await parse(schema);
      } catch (error) {
        console.log(error.message);
      }
      if (result) setSchemaStore(result);
    })();
  }, [schema]);

  // const config = {
  //   sparqlEndpoint: "/store/repositories/star/statements",
  //   sparqlUpdateEndpoint: "/store/repositories/star/statements",
  // };

  return (
    <RouterProvider router={router(schemaStore)} />
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeCustomization>
      <ScrollTop>

        <App schema={Schema} />

      </ScrollTop>
    </ThemeCustomization>

  </React.StrictMode>
);
