// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react';
import { Container, Typography } from '@mui/material';


import Instances from '../../api/tables/Instances.tsx';


const InstanceTable: React.FC = () => {


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Instances
            </Typography>
            <Instances />
        </Container>
    );
};

export default InstanceTable;

