// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Persons as PersonsQuery } from '../../data/Queries.ts';
import { FetchData } from '../FetchData.tsx';

const parsePersons = (results) => {

    return results.map((binding, index) => ({
        id: index,
        person: binding.person.value,
        name: binding.name?.value ?? null,
    }));
};

const deletePerson = async (personData) => {
    try {
        const subject = personData.person;
        const url = `${process.env.REACT_APP_API_STATEMENTS}`;
        const deleteQuery = `
            DELETE WHERE {
                <${subject}> ?p ?o .
            }
        `;
        const headers = {
            'Content-Type': 'application/sparql-update'
        };
        await axios.post(url, deleteQuery, { headers });
    } catch (err) {
        console.log('Error deleting row');
    }
}

const Persons: React.FC = () => {
    const [persons, setPersons] = useState([]);

    useEffect(() => {
        const Request = {
            query: PersonsQuery,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;
            const persons = parsePersons(bindings);
            setPersons(persons);
        };

        fetch();
    }, []);


    const handleDelete = async (person) => {
        await deletePerson(person);
        setPersons((prevPersons) => prevPersons.filter((item) => item.person !== person.person));
        alert('Source Passage deleted successfully!');
    };

    const columns = [
        { field: 'person', headerName: 'URI', width: 600 },
        { field: 'name', headerName: 'Name', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(params.row)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid rows={persons} columns={columns} initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 5,
                    },
                },
            }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick />
        </Box>
    );
};

export default Persons;
