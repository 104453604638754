// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import Dashboard from '../layout/Dashboard';
import DefaultFrame from "@fe/roger-frames";
//import SparqlEditor from "../components/SparqlEditor.tsx";
import View from '../components/View.tsx';
import Edit from '../components/Edit.tsx';
import SearchAnnotations from '../components/SearchAnnotations.tsx';
import AnnotationsTable from '../components/datagrid/AnnotationsTable.tsx';
import AuthorTable from '../components/datagrid/AuthorTable.tsx';
import SourcePassageTable from '../components/datagrid/SourcePassageTable.tsx';
import EgotextPassageTable from '../components/datagrid/EgotextPassageTable.tsx';
import ResearchPassageTable from '../components/datagrid/ResearchPassageTable.tsx';
import InstanceTable from '../components/datagrid/InstanceTable.tsx';
import WorkTable from '../components/datagrid/WorkTable.tsx';
import PersonTable from '../components/datagrid/PersonTable.tsx';


const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/index')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('../pages/extra-pages/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (schemaStore) => {
  const config = {
    sparqlEndpoint: `${process.env.REACT_APP_API_SPARQL}`,
    sparqlUpdateEndpoint: `${process.env.REACT_APP_API_STATEMENTS}`,
  };

  return {
    path: '/',
    element: <Dashboard />,
    children: [
      {
        path: '/',
        element: <DashboardDefault />
      },
      {
        path: 'dashboard',
        children: [
          {
            path: 'default',
            element: <DashboardDefault />
          }
        ]
      },
      {
        path: 'annotate',
        element: <DefaultFrame schema={schemaStore} config={config} />
      },
      // {
      //   path: 'sparql',
      //   element: <SparqlEditor />
      // },
      {
        path: 'view/:id',
        element: <View />
      },
      {
        path: 'edit/:id',
        element: <Edit />
      },
      {
        path: 'search',
        element: <SearchAnnotations />
      },
      {
        path: 'searchannotation',
        element: <AnnotationsTable />
      },
      {
        path: 'searchauthor',
        element: <AuthorTable />
      },
      {
        path: 'searcsource',
        element: <SourcePassageTable />
      },
      {
        path: 'searchegotext',
        element: <EgotextPassageTable />
      },
      {
        path: 'searchresearch',
        element: <ResearchPassageTable />
      },
      {
        path: 'searchinstance',
        element: <InstanceTable />
      },
      {
        path: 'searchwork',
        element: <WorkTable />
      },
      {
        path: 'searchperson',
        element: <PersonTable />
      },
      {
        path: 'viewannotation/:uri',
        element: <View />
      },
      {
        path: 'sample-page',
        element: <SamplePage />
      },
    ]
  };
};

export default MainRoutes;

