// SPDX-FileCopyrightText: 2022 CodedThemes (Mantis Theme)
//
// SPDX-License-Identifier: MIT

// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12
        }
      }
    }
  };
}
