// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

// project import
import dashboard from './dashboard';
//import pages from './page';
import utilities from './utilities';
//import support from './support';
import search from './search';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, utilities, search]
};

export default menuItems;
