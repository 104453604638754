// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import { v4 as uuidv4 } from 'uuid';


const rdfData = (combinedData: any) => {
  const created = new Date().toISOString();

  const uniqueId = uuidv4();
  const literarySource = uuidv4();
  const literarySource_passage = uuidv4();
  const notedIn = uuidv4();
  const egotext = uuidv4();
  const instace = uuidv4();
  const instance_passage = uuidv4();
  const place = uuidv4();
  const publisher = uuidv4();
  const work = uuidv4();


  return `
          @prefix sh: <http://www.w3.org/ns/shacl#> .
          @prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
          @prefix sorsh: <http://example.org/rosenzweig/annotation_shapes/> .
          @prefix sor: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotation#> .
          @prefix sord: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotationData#> .
          @prefix sorvoc: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotationVocabulary#> .
          @prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
          @prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
          @prefix roger: <https://www.sub.uni-goettingen.de/roger/schema#> .
          @prefix dash: <http://datashapes.org/dash#> .
          @prefix skos: <http://www.w3.org/2004/02/skos/core#> .
          @prefix dcterms: <http://purl.org/dc/terms#> .
          @prefix bf: <http://id.loc.gov/ontologies/bibframe-2-2-0#> .
          @prefix oa: <http://www.w3.org/ns/oa#> .
          @prefix foaf: <http://xmlns.com/foaf/0.1#> .
          @prefix owl: <http://www.w3.org/2002/07/owl#> .
          @prefix intro: <https://w3id.org/lso/intro/beta202304#> .

          ### Specific to controlled vocabularies
          @prefix dct: <https://purl.org/dc/terms/> .
          @prefix locvoc: <http://id.loc.gov/vocabulary/relators/#> .

          ### Web annotation model

          sord:annotation_${uniqueId} a oa:Annotation ;

          ### User management
          dcterms:created "${created}"^^xsd:dateTime ;
          dcterms:modified "${created}"^^xsd:dateTime ;

          # Target Shape
      oa:hasTarget
          [a oa:SpecificResource;
              oa:hasSelector
                  [a oa:RangeSelector;
                      oa:hasStartSelector
                          [a oa:XPathSelector;
                              rdf:value "${combinedData.startData.dataInfo}" ];
                      oa:hasEndSelector
                          [a oa:XPathSelector;
                              rdf:value "${combinedData.endData.dataInfo}"];
                      oa:refinedBy 
                          [a oa:TextQuoteSelector;
                              oa:exact "${combinedData.endData.selectedText}"], 
                          [a sor:XMLIDSelector;
                              sor:startID "${combinedData.startData.targetId}";
                              sor:endID "${combinedData.endData.targetId}"],
                          [a oa:DataPositionSelector;
                              oa:start ${combinedData.startData.startOffset};
                              oa:end ${combinedData.endData.endOffset}]
                  ];

              oa:hasSource <https://gitlab.gwdg.de/-/ide/project/star/rosenzweig-models/edit/main/-/RDF/Rosenzweig_exampledata.ttl>;
              ];

          ### Intertextual Relationships
          oa:hasBody [
            a intro:INT7_Interpretament ;
            sor:note "" ;
            sor:firstProposer true ;
            sor:author sord:person_37183643-eaaf-4ee9-b550-1cf947874524 ;
            intro:R21_identifies [
              a intro:INT3_IntertextualRelationship ;
              intro:R19_has_type sorvoc:DirectReference ;
              sor:hasFormalAppearance [
                a sor:FormalAppearance ;
                sor:hasQuotationMarks false ;
                sor:titleStated false ;
                sor:authorStated true ;
                sor:literarySource sord:passage_${literarySource} ;
                sor:author sord:person_37183643-eaaf-4ee9-b550-1cf947874527
              ]
            ] ;
            sor:literarySource sord:passage_${literarySource_passage} ;
            sor:notedIn sord:passage_${notedIn} ;
            sor:egotext sord:passage_${egotext}
          ] .

          <https://gitlab.gwdg.de/-/ide/project/star/rosenzweig-models/edit/main/-/RDF/Rosenzweig_exampledata.ttl> a sor:XMLresource .

          ### Persons
          sord:person_37183643-eaaf-4ee9-b550-1cf947874524 a foaf:Person ;
            foaf:name "Ynon Wygoda" .

          sord:person_7c15e366-c9a2-4dbc-afa2-1ad5bd9a1ff0 a foaf:Person ;
            foaf:name "Nathanael Schweikhard" .

          sord:person_ae6192cf-7333-4b4a-c751-e5646a7c5547 a foaf:Person ;
            foaf:name "Mohamed Amer" .

          sord:person_ae6192cf-7333-4b4a-c751-e5646a7c5543 a foaf:Person ;
            foaf:name "Friedrich Schiller" .

          sord:person_37183643-eaaf-4ee9-b550-1cf947874527 a foaf:Person ;
            foaf:name "Johann Wolfgang von Goethe" ;
            skos:exactMatch <https://d-nb.info/gnd/121488624> .

          <https://d-nb.info/gnd/121488624> a sor:GND .

          ### Passages
          sord:passage_${literarySource_passage} a sor:SourcePassage ;
            intro:R44_has_wording "exact text of the passage" ;
            sor:context "The exact text of the passage and what surrounds it." ;
            bf:part "13" ;
            sor:originalLink "http://www.example.com"^^xsd:anyURI ;
            sor:modifiedLink "http://www.beispiel.de"^^xsd:anyURI ;
            bf:partOf sord:instance_${instace} .

          sord:passage_${egotext} a sor:EgotextPassage ;
            bf:part "13" ;
            bf:partOf sord:instance_${instace} .

          sord:passage_${notedIn} a sor:ResearchLiteraturePassage ;
            bf:part "13" ;
            bf:partOf sord:instance_${instace} .

          sord:passage_${literarySource} a sor:SourcePassage ;
            intro:R44_has_wording "exact text of the passage" ;
            sor:context "The exact text of the passage and what surrounds it." ;
            bf:part "13" ;
            sor:originalLink "http://www.example.com"^^xsd:anyURI ;
            sor:modifiedLink "http://www.beispiel.de"^^xsd:anyURI ;
            bf:partOf sord:instance_${instace} ;
            sor:equivalentPassage sord:passage_${literarySource_passage} .

          ### Instances
          sord:instance_${instance_passage} a bf:Instance .

          sord:instance_${instace} a bf:Instance ;
            bf:partOf sord:instance_${instance_passage} ;
            bf:electronicLocator "http://www.example.com"^^xsd:anyURI ;
            sor:pages "1-30" ;
            bf:identifiedBy <urn:isbn:978-3-7657-1111-4> ;
            bf:dissertation [
              a bf:Dissertation ;
              bf:grantingInstitution sord:grantingInstitution_f782d0a3-de59-449f-f562-57926592a719 ;
              bf:degree "PhD"
            ] ;
            bf:title [
              a bf:Title ;
              bf:mainTitle "main title here" ;
              bf:subtitle "subtitle here"
            ] ;
            bf:provisionActivity [
              a bf:Publication ;
              bf:place sord:place_f782d0a3-de59-449f-f562-57926592a719 ;
              bf:agent sord:publisher_${publisher} ;
              bf:date "2015-04-30"
            ] ;
            bf:contribution [
              a bf:Contribution ;
              bf:role sorvoc:Editor ;
              bf:agent sord:person_ae6192cf-7333-4b4a-c751-e5646a7c5547
            ] ;
            bf:instanceOf sord:work_${work} .

          <urn:isbn:978-3-7657-1111-4> a bf:Isbn .

          sord:grantingInstitution_f782d0a3-de59-449f-f562-57926592a719 a sor:GrantingInstitution ;
            foaf:name "Georg August University of Göttingen" .

          sord:place_f782d0a3-de59-449f-f562-57926592a719 a bf:Place ;
            foaf:name "Berlin" .

          sord:publisher_${publisher} a sor:Publisher ;
            foaf:name "De Gruyter" .

          ### Work
          sord:work_${work} a bf:Work ;
            bf:originDate "2002-03-14" ;
            bf:title [
              a bf:Title ;
              bf:mainTitle "main title here" ;
              bf:subtitle "subtitle here"
            ] ;
            bf:contribution [
              a bf:Contribution ;
              bf:role sorvoc:Author ;
              bf:agent sord:person_ae6192cf-7333-4b4a-c751-e5646a7c5543
            ] ;
            skos:exactMatch <https://www.wikidata.org/wiki/Q29478> ;
            skos:exactMatch <https://lccn.loc.gov/18000823> .

          <https://www.wikidata.org/wiki/Q29478> a sor:Wikidata .

          <https://lccn.loc.gov/18000823> a sor:LOC .

          ### Controlled Vocabularies
          sorvoc:Author a skos:Concept .
          sorvoc:Editor a skos:Concept .
          sorvoc:Translator a skos:Concept .
          sorvoc:Recipient a skos:Concept .
          sorvoc:DirectReference a skos:Concept .
          sorvoc:Paraphrase a skos:Concept .
          sorvoc:TechnicalTerm a skos:Concept .
        `;
};

export default rdfData;
