// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: CC0-1.0
// SPDX-License-Identifier: EUPL-1.2

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const baseURL = "http://localhost:3000/";

type Anchor = "right";

interface ParsedSections {
  id: string;
  paragraphs: [];
  quotes: [];
  subsections: [];
  title: string;
  xpath: string;
}

interface Section {
  id: string;
  title: string;
  subsections: Subsection[];
}

interface Subsection {
  id: string;
  title: string;
}

interface ParsedSections extends Array<ParsedSections> { }

const RotatedButton = styled.button`
  transform: rotate(-90deg);
  transform-origin: bottom right;
  position: fixed;
  bottom: 80%;
  right: 20px;
  display: flex;
  flexdirection: column;
  alignitems: center;
  background-color: #1976d2;
  color: #fff;
  padding: 7px;
  border: solid 0.05px #1976d2;
  font-size: 18px;

  /* Styles for hover */
  &:hover {
    background-color: #fff;
    color: #1976d2;
    transition: background-color 0.3s, color 0.3s;
    border: solid 0.05px #1976d2;
  }
`;
export default function ContentTable({
  parsedSections,
}: {
  parsedSections: Section[];
}) {
  //console.log(parsedSections);

  const { t, i18n } = useTranslation();
  const [state, setState] = React.useState({
    right: false,
  });

  //collapsed menu item is closed by default otherwise :true
  const [open, setOpen] = React.useState(false);

  const [sectionStates, setSectionStates] = useState(
    parsedSections.map(() => false)
  );

  const handleSectionClick = (index: number) => {
    setSectionStates((prevSectionStates) => {
      const newSectionStates = [...prevSectionStates];
      newSectionStates[index] = !newSectionStates[index];
      return newSectionStates;
    });
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "right" ? "auto" : 500 }}
      role="presentation"
      onClick={() => toggleDrawer(anchor, true)}
      onKeyDown={() => toggleDrawer(anchor, false)}
    >
      <List
        sx={{ width: "100%", maxWidth: 720, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <Typography variant="h6" sx={{ py: 4 }}>
              {t("Table_of_content")}
            </Typography>
          </ListSubheader>
        }
      >
        {parsedSections.map((section, index) => (
          <div key={section.id}>
            {section.subsections && section.subsections.length == 0 ? (
              <Link
                href={`#${section.id}`}
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <BookmarkBorderOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={section.title} />
                </ListItemButton>
              </Link>
            ) : (
              <ListItemButton onClick={() => handleSectionClick(index)}>
                <ListItemIcon>
                  <BookmarkBorderOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={section.title} />
                {sectionStates[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}

            <Divider />
            <Collapse in={sectionStates[index]} timeout="auto" unmountOnExit>
              {
                <Link
                  href={`#${section.id}`}
                  onClick={() => toggleDrawer(anchor, false)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <BookmarkBorderOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={section.title} />
                  </ListItemButton>
                </Link>
              }

              {section.subsections.map((subsection) => (
                <div key={subsection.id}>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      <BookmarksOutlinedIcon sx={{ px: 2 }} />
                      <Link
                        href={`#${section.id}`}
                        onClick={toggleDrawer(anchor, false)}
                      >
                        <ListItemText primary={subsection.title} />
                      </Link>
                    </ListItemButton>
                  </List>
                </div>
              ))}
            </Collapse>
          </div>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <RotatedButton onClick={toggleDrawer("right", true)}>
          {t("Table_of_content")}
        </RotatedButton>

        <Drawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
