// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { AnnotationDetailsQuery } from '../data/Queries.ts';
import { FetchData } from '../api/FetchData.tsx';
import { MainDetails, AuthorDetails, LiterarySourceDetails, EgotextDetails } from './Sections.tsx';



const View: React.FC = () => {
    const { uri } = useParams();
    const [annotationDetails, setAnnotationDetails] = useState(null);
    const parseAnnotation = (results) => {
        if (!results.length) return null;

        const binding = results[0];
        return {
            target: binding.target.value,
            startSelector: binding.startSelector?.value ?? null,
            startValue: binding.startValue?.value ?? null,
            endSelector: binding.endSelector?.value ?? null,
            endValue: binding.endValue?.value ?? null,
            firstProposer: binding.firstProposer?.value ?? null,
            r21_identifies: binding.r21_identifies?.value ?? null,
            formalAppearance: binding.formalAppearance?.value ?? null,
            hasQuotationMarks: binding.hasQuotationMarks?.value ?? null,
            titleStated: binding.titleStated?.value ?? null,
            authorStated: binding.authorStated?.value ?? null,
            literarySource: binding.literarySource?.value ?? null,
            author2: binding.author2?.value ?? null,
            dataPositionSelector: binding.dataPositionSelector?.value ?? null,
            startOffset: binding.startOffset ? parseInt(binding.startOffset.value, 10) : null,
            endOffset: binding.endOffset ? parseInt(binding.endOffset.value, 10) : null,
            source: binding.source.value,
            textQuoteSelector: binding.textQuoteSelector?.value ?? null,
            exact: binding.exact?.value ?? null,
            xmlIDSelector: binding.xmlIDSelector?.value ?? null,
            startID: binding.startID?.value ?? null,
            endID: binding.endID?.value ?? null,
            note: binding.note?.value ?? null,
            author: binding.author?.value,
            authorName: binding.authorName?.value ?? null,
            created: binding.created?.value ?? null,
            literarysource: binding.literarysource?.value ?? null,
            notedin: binding.notedin?.value ?? null,
            egotext: binding.egotext?.value ?? null,
            egotextpart: binding.egotextpart?.value ?? null,
            instance: binding.instance?.value ?? null,
            work: binding.work?.value ?? null,
            electronicLocator: binding.electronicLocator?.value ?? null,
            sorpages: binding.sorpages?.value ?? null,
            identifiedBy: binding.identifiedBy?.value ?? null,
            has_wording: binding.has_wording?.value ?? null,
            sorcontext: binding.sorcontext?.value ?? null,
            resourcepart: binding.resourcepart?.value ?? null,
            originalLink: binding.originalLink?.value ?? null,
            modifiedLink: binding.modifiedLink?.value ?? null,
            sourcepartof: binding.sourcepartof?.value ?? null,
        };
    };


    useEffect(() => {

        const query = AnnotationDetailsQuery(uri);
        const Request = {
            query: query,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;
            console.log('bindidng', bindings);
            const annotation = parseAnnotation(bindings);
            setAnnotationDetails(annotation);

        };

        fetch();



    }, [uri]);

    if (!annotationDetails) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Annotation Details
            </Typography>
            <MainDetails details={annotationDetails} />
            <AuthorDetails details={annotationDetails} />
            <LiterarySourceDetails details={annotationDetails} />
            <EgotextDetails details={annotationDetails} />
            {/* Add more sections as needed */}
        </Container>
    );
};

export default View;
