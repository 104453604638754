// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react';
import { Container, Typography } from '@mui/material';


import Works from '../../api/tables/Works.tsx';


const WorkTable: React.FC = () => {


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Works
            </Typography>
            <Works />
        </Container>
    );
};

export default WorkTable;

