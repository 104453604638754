// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/lab/Autocomplete';
import { Instances as InstancesQuery } from '../../data/Queries.ts';
import { FetchData } from '../FetchData.tsx';


const parseInstances = (results) => {
    return results.map(binding => ({
        instance: binding.instance.value,
        name: binding.name?.value ?? null,
        workmaintitle: binding.workmaintitle?.value,
        worksubtitle: binding.worksubtitle?.value ?? null,
        instancemaintitle: binding.instancemaintitle?.value ?? null,
        instancesubtitle: binding.instancesubtitle?.value ?? null,
    }));
};

const deleteInstance = async (instancesData) => {
    try {
        const subject = instancesData.instance;
        const url = `${process.env.REACT_APP_API_STATEMENTS}`;
        const deleteQuery = `
            DELETE WHERE {
                <${subject}> ?p ?o .
            }
        `;
        const headers = {
            'Content-Type': 'application/sparql-update'
        };
        await axios.post(url, deleteQuery, { headers });
    } catch (err) {
        console.log('Error deleting row');
    }
}


const Instances: React.FC = () => {
    const [instances, setInstances] = useState([]);
    const [selectedInstances, setSelectedInstances] = useState(null);
    const [formData, setFormData] = useState({});


    useEffect(() => {
        const Request = {
            query: InstancesQuery,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;

            const sourcePassages = parseInstances(bindings);
            setInstances(sourcePassages);
        };

        fetch();
    }, []);

    const handleSourcePassageChange = (event, value) => {
        setSelectedInstances(value);
        setFormData(value);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleUpdate = async () => {
        //await updateAnnotation(formData);
        alert('Annotation updated successfully!');
    };

    const handleDeleteInstance = async () => {
        await deleteInstance(formData);
        alert('Source Passage deleted successfully!');
    }


    return (



        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Search by Instances</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Autocomplete
                        options={instances}
                        getOptionLabel={(option) => option.instancemaintitle}
                        onChange={handleSourcePassageChange}
                        renderInput={(params) => <TextField {...params} label="Source Passage (Subject)" variant="outlined" margin="normal" fullWidth />}
                        sx={{ width: '100%' }}
                    />
                    {selectedInstances && (
                        <Box sx={{ width: '100%' }}>
                            {Object.entries(formData).map(([key, value]) => (
                                key !== 'instance' && (
                                    <TextField
                                        key={key}
                                        label={key}
                                        name={key}
                                        value={value || ''}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        onChange={handleInputChange}
                                    />
                                )
                            ))}
                            <Button variant="contained" color="primary" onClick={handleUpdate} style={{ margin: 2 }}>
                                Update Passage
                            </Button>
                            <Button variant="contained" color="error" onClick={handleDeleteInstance}>
                                Delete Passage
                            </Button>
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>

    );
};

export default Instances;

