// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function ListItemIcon() {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 24
        }
      }
    }
  };
}
