// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react'
import axios from 'axios';
import Prefixes from '../data/Prefixes.ts';



// const FetchData = async (query, headers, url) => {
//     console.log('FetchData', query, headers, url);
//     try {
//         const complete_query = `
//                             ${Prefixes}
//                             ${query}
//                         `;

//         const response = await axios.post(url, complete_query, { headers });
//         return (response);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// };


const FetchData = async (Request) => {
    try {
        const query = `
        ${Prefixes}
        ${Request.query}
        `;
        const headers = Request.headers;
        const response = await axios.post(Request.url, query, { headers });
        //const bindings = response.data.results.bindings;
        //const annotations = parseAnnotations(bindings);
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};


export { FetchData }
