// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
const AllAnnotationsQuery =
        `SELECT ?annotation ?exact ?note ?author ?authorName ?created
                WHERE {
                ?annotation a oa:Annotation ;
                        dcterms:created ?created ;
                                        oa:hasBody ?body .

                ?body a intro:INT7_Interpretament ;
                        sor:note ?note ;
                        sor:author ?author .

                ?author foaf:name ?authorName .

                OPTIONAL {
                ?annotation oa:hasTarget ?target .
                ?target a oa:SpecificResource ;
                        oa:hasSelector ?rangeSelector .

                ?rangeSelector a oa:RangeSelector ;
                                oa:refinedBy ?textQuoteSelector .

                ?textQuoteSelector a oa:TextQuoteSelector ;
                                oa:exact ?exact .
                }
        }`;

const AnnotationsCountQuery = `
          SELECT (COUNT(DISTINCT ?annotation) AS ?annotationCount)
          WHERE {
            ?annotation a oa:Annotation
          }
        `;
const PropertiesCountQuery = `
          SELECT (COUNT(DISTINCT ?predicate) AS ?predicateCount)
          WHERE {
            ?annotation a oa:Annotation ;
                        ?predicate ?object .
          }
        `;
const ObjectsCountQuery = `
          SELECT (COUNT(DISTINCT ?object) AS ?objectCount)
          WHERE {
            ?annotation a oa:Annotation ;
                        ?predicate ?object .
          }
        `;
const RecentAnnotationsQuery =
        `SELECT ?annotation ?exact ?note ?author ?authorName ?created
                WHERE {
                ?annotation a oa:Annotation ;
                        dcterms:created ?created ;
                                        oa:hasBody ?body .

                ?body a intro:INT7_Interpretament ;
                        sor:note ?note ;
                        sor:author ?author .

                ?author foaf:name ?authorName .

                OPTIONAL {
                ?annotation oa:hasTarget ?target .
                ?target a oa:SpecificResource ;
                        oa:hasSelector ?rangeSelector .

                ?rangeSelector a oa:RangeSelector ;
                                oa:refinedBy ?textQuoteSelector .

                ?textQuoteSelector a oa:TextQuoteSelector ;
                                oa:exact ?exact .
                }
        }`;
const CompleteAnnotationsQuery =
        `SELECT ?annotation ?target ?targetType ?startSelector ?startValue ?endSelector ?endValue ?dataPositionSelector ?startOffset ?endOffset ?source ?textQuoteSelector ?exact ?xmlIDSelector ?startID ?endID ?note ?author ?authorName ?created ?literarysource ?notedin ?egotext ?egotextpart ?instance ?work ?electronicLocator ?sorpages ?identifiedBy ?has_wording ?sorcontext ?resourcepart ?originalLink ?modifiedLink ?sourcepartof ?firstProposer ?r21_identifies ?formalAppearance ?hasQuotationMarks ?titleStated ?authorStated ?author2
                WHERE {
                ?annotation a oa:Annotation ;
                            oa:hasTarget ?target ;
                            dcterms:created ?created ;
                            oa:hasBody ?body .

                ?target a oa:SpecificResource ;
                        oa:hasSelector ?rangeSelector ;
                        oa:hasSource ?source .

                ?body a intro:INT7_Interpretament ;
                    sor:note ?note ;
                    sor:firstProposer ?firstProposer ;
                    intro:R21_identifies ?r21_identifies ;
                    sor:author ?author ;
                    sor:literarySource ?literarysource ;
                    sor:notedIn ?notedin ;
                    sor:egotext ?egotext .


                ?r21_identifies a intro:INT3_IntertextualRelationship ;
                        intro:R19_has_type sorvoc:DirectReference ;
                        sor:hasFormalAppearance ?formalAppearance .

                ?formalAppearance a sor:FormalAppearance ;
                        sor:hasQuotationMarks ?hasQuotationMarks ;
                        sor:titleStated ?titleStated ;
                        sor:authorStated ?authorStated ;
                        sor:literarySource ?literarySource ;
                        sor:author ?author2 .

                ?egotext a sor:EgotextPassage;
                        bf:part ?egotextpart ;
                        bf:partOf ?instance .

                ?instance a bf:Instance ;
                        bf:partOf ?work ;
                        bf:electronicLocator ?electronicLocator;
                        sor:pages ?sorpages ;
                        bf:identifiedBy ?identifiedBy.

                ?literarysource a sor:SourcePassage ;
                        intro:R44_has_wording ?has_wording ;
                        sor:context ?sorcontext ;
                        bf:part ?resourcepart ;
                        sor:originalLink ?originalLink ;
                        sor:modifiedLink ?modifiedLink ;
                        bf:partOf ?sourcepartof.

                ?author foaf:name ?authorName .

                ?rangeSelector a oa:RangeSelector ;
                                oa:hasStartSelector ?startSelector ;
                                oa:hasEndSelector ?endSelector .

                ?startSelector rdf:value ?startValue .
                ?endSelector rdf:value ?endValue .

                OPTIONAL {
                    ?rangeSelector oa:refinedBy ?dataPositionSelector .
                    ?dataPositionSelector a oa:DataPositionSelector ;
                                        oa:start ?startOffset ;
                                        oa:end ?endOffset .
                }

                OPTIONAL {
                    ?rangeSelector oa:refinedBy ?textQuoteSelector .
                    ?textQuoteSelector a oa:TextQuoteSelector ;
                                    oa:exact ?exact .
                }

                OPTIONAL {
                    ?rangeSelector oa:refinedBy ?xmlIDSelector .
                    ?xmlIDSelector a sor:XMLIDSelector ;
                                sor:startID ?startID ;
                                sor:endID ?endID .
                }

                OPTIONAL { ?target oa:hasSource ?source . }
                OPTIONAL { ?target a ?targetType . }
        }`;
const SourcePassages = `select ?sourcepassage ?haswording ?name ?workmaintitle ?worksubtitle ?name2 ?workmaintitle2 ?worksubtitle2 ?instancemaintitle ?instancesubtitle ?instancemaintitle2 ?instancesubtitle2 where
                        {{
                        ?sourcepassage rdf:type sor:SourcePassage ;
                                        bf:partOf ?instance ;
                                        intro:R44_has_wording ?haswording .
                        ?instance bf:title ?instancetitle ;
                                bf:partOf ?instance2 ;
                                bf:instanceOf ?work .
                        ?instancetitle bf:mainTitle ?instancemaintitle .
                        ?work bf:title ?worktitle .
                        ?worktitle bf:mainTitle ?workmaintitle .
                        ?instance2 bf:title ?instancetitle2 ;
                                bf:instanceOf ?work2 .
                        ?instancetitle2 bf:mainTitle ?instancemaintitle2 .
                        ?work2 bf:title ?worktitle2 .
                        ?worktitle2 bf:mainTitle ?workmaintitle2 .
                        optional { ?instancetitle bf:subtitle ?instancesubtitle }
                        optional { ?instancetitle2 bf:subtitle ?instancesubtitle2 }
                        optional { ?worktitle bf:subtitle ?worksubtitle }
                        optional { ?worktitle2 bf:subtitle ?worksubtitle2 }
                        optional { ?work bf:contribution ?workcontribution .
                                ?workcontribution bf:role sorvoc:Author .
                                ?workcontribution bf:agent ?author .
                                ?author foaf:name ?name
                        }
                        optional { ?work2 bf:contribution ?workcontribution2 .
                                ?workcontribution2 bf:role sorvoc:Author .
                                ?workcontribution2 bf:agent ?author2 .
                                ?author2 foaf:name ?name2
                        }
                        }
                        UNION
                        {
                        ?sourcepassage rdf:type sor:SourcePassage ;
                                        bf:partOf ?instance ;
                                        intro:R44_has_wording ?haswording .
                        ?instance bf:title ?instancetitle ;
                                bf:instanceOf ?work .
                        ?instancetitle bf:mainTitle ?instancemaintitle .
                        ?work bf:title ?worktitle .
                        ?worktitle bf:mainTitle ?workmaintitle .
                        optional { ?instancetitle bf:subtitle ?instancesubtitle }
                        optional { ?worktitle bf:subtitle ?worksubtitle }
                        optional { ?work bf:contribution ?workcontribution .
                                ?workcontribution bf:role sorvoc:Author .
                                ?workcontribution bf:agent ?author .
                                ?author foaf:name ?name
                        }
                        }}`;
const EgotextPassages = `select ?egotextpassage ?haswording ?name ?workmaintitle ?worksubtitle ?name2 ?workmaintitle2 ?worksubtitle2 ?instancemaintitle ?instancesubtitle ?instancemaintitle2 ?instancesubtitle2 where
                        {{
                        ?egotextpassage rdf:type sor:EgotextPassage ;
                                        bf:partOf ?instance .
                        ?instance bf:title ?instancetitle ;
                                bf:partOf ?instance2 ;
                                bf:instanceOf ?work .
                        ?instancetitle bf:mainTitle ?instancemaintitle .
                        ?work bf:title ?worktitle .
                        ?worktitle bf:mainTitle ?workmaintitle .
                        ?instance2 bf:title ?instancetitle2 ;
                                bf:instanceOf ?work2 .
                        ?instancetitle2 bf:mainTitle ?instancemaintitle2 .
                        ?work2 bf:title ?worktitle2 .
                        ?worktitle2 bf:mainTitle ?workmaintitle2 .
                        optional { ?instancetitle bf:subtitle ?instancesubtitle }
                        optional { ?instancetitle2 bf:subtitle ?instancesubtitle2 }
                        optional { ?worktitle bf:subtitle ?worksubtitle }
                        optional { ?worktitle2 bf:subtitle ?worksubtitle2 }
                        optional { ?work bf:contribution ?workcontribution .
                                ?workcontribution bf:role sorvoc:Author .
                                ?workcontribution bf:agent ?author .
                                ?author foaf:name ?name
                        }
                        optional { ?work2 bf:contribution ?workcontribution2 .
                                ?workcontribution2 bf:role sorvoc:Author .
                                ?workcontribution2 bf:agent ?author2 .
                                ?author2 foaf:name ?name2
                        }
                        }
                        UNION
                        {
                        ?egotextpassage rdf:type sor:EgotextPassage ;
                                        bf:partOf ?instance .
                        ?instance bf:title ?instancetitle ;
                                bf:instanceOf ?work .
                        ?instancetitle bf:mainTitle ?instancemaintitle .
                        ?work bf:title ?worktitle .
                        ?worktitle bf:mainTitle ?workmaintitle .
                        optional { ?instancetitle bf:subtitle ?instancesubtitle }
                        optional { ?worktitle bf:subtitle ?worksubtitle }
                        optional { ?work bf:contribution ?workcontribution .
                                ?workcontribution bf:role sorvoc:Author .
                                ?workcontribution bf:agent ?author .
                                ?author foaf:name ?name
                        }
                        }}`;
const ResearchPassages = `select ?researchliteraturepassage ?part ?name ?workmaintitle ?worksubtitle ?name2 ?workmaintitle2 ?worksubtitle2 ?instancemaintitle ?instancesubtitle ?instancemaintitle2 ?instancesubtitle2 where
                        {{
                        ?researchliteraturepassage rdf:type sor:ResearchLiteraturePassage ;
                                        bf:partOf ?instance ;
                                        bf:part ?part .
                        ?instance bf:title ?instancetitle ;
                                bf:partOf ?instance2 ;
                                bf:instanceOf ?work .
                        ?instancetitle bf:mainTitle ?instancemaintitle .
                        ?work bf:title ?worktitle .
                        ?worktitle bf:mainTitle ?workmaintitle .
                        ?instance2 bf:title ?instancetitle2 ;
                                bf:instanceOf ?work2 .
                        ?instancetitle2 bf:mainTitle ?instancemaintitle2 .
                        ?work2 bf:title ?worktitle2 .
                        ?worktitle2 bf:mainTitle ?workmaintitle2 .
                        optional { ?instancetitle bf:subtitle ?instancesubtitle }
                        optional { ?instancetitle2 bf:subtitle ?instancesubtitle2 }
                        optional { ?worktitle bf:subtitle ?worksubtitle }
                        optional { ?worktitle2 bf:subtitle ?worksubtitle2 }
                        optional { ?work bf:contribution ?workcontribution .
                                ?workcontribution bf:role sorvoc:Author .
                                ?workcontribution bf:agent ?author .
                                ?author foaf:name ?name
                        }
                        optional { ?work2 bf:contribution ?workcontribution2 .
                                ?workcontribution2 bf:role sorvoc:Author .
                                ?workcontribution2 bf:agent ?author2 .
                                ?author2 foaf:name ?name2
                        }
                        }
                        UNION
                        {
                        ?researchliteraturepassage rdf:type sor:ResearchLiteraturePassage ;
                                        bf:partOf ?instance ;
                                        bf:part ?part .
                        ?instance bf:title ?instancetitle ;
                                bf:instanceOf ?work .
                        ?instancetitle bf:mainTitle ?instancemaintitle .
                        ?work bf:title ?worktitle .
                        ?worktitle bf:mainTitle ?workmaintitle .
                        optional { ?instancetitle bf:subtitle ?instancesubtitle }
                        optional { ?worktitle bf:subtitle ?worksubtitle }
                        optional { ?work bf:contribution ?workcontribution .
                                ?workcontribution bf:role sorvoc:Author .
                                ?workcontribution bf:agent ?author .
                                ?author foaf:name ?name
                        }
                        }}`;
const Instances = `select ?instance ?name ?workmaintitle ?worksubtitle ?instancemaintitle ?instancesubtitle where
                        {
                        ?instance bf:title ?instancetitle ;
                                bf:instanceOf ?work .
                        ?instancetitle bf:mainTitle ?instancemaintitle .
                        ?work bf:title ?worktitle .
                        ?worktitle bf:mainTitle ?workmaintitle .
                        optional { ?instancetitle bf:subtitle ?instancesubtitle }
                        optional { ?worktitle bf:subtitle ?worksubtitle }
                        optional { ?work bf:contribution ?workcontribution .
                                ?workcontribution bf:role sorvoc:Author .
                                ?workcontribution bf:agent ?author .
                                ?author foaf:name ?name
                        }
                        }`;
const Works = `select ?work ?name ?workmaintitle ?worksubtitle where
                {
                ?work bf:title ?worktitle ;
                        rdf:type bf:Work .
                ?worktitle bf:mainTitle ?workmaintitle .
                optional { ?worktitle bf:subtitle ?worksubtitle }
                optional { ?work bf:contribution ?workcontribution .
                        ?workcontribution bf:role sorvoc:Author .
                        ?workcontribution bf:agent ?author .
                        ?author foaf:name ?name
                }
                }`;
const Persons = `select ?person ?name
                {
                ?workcontribution a bf:Contribution ;
                                bf:agent ?person .
                ?person foaf:name ?name
                }`;

const AnnotationDetailsQuery = (uri) => {
        const decodedUri = decodeURIComponent(uri);
        return `
        SELECT ?annotation ?target ?targetType ?startSelector ?startValue ?endSelector ?endValue ?dataPositionSelector ?startOffset ?endOffset ?source ?textQuoteSelector ?exact ?xmlIDSelector ?startID ?endID ?note ?author ?authorName ?created ?literarysource ?notedin ?egotext ?egotextpart ?instance ?work ?electronicLocator ?sorpages ?identifiedBy ?has_wording ?sorcontext ?resourcepart ?originalLink ?modifiedLink ?sourcepartof ?firstProposer ?r21_identifies ?formalAppearance ?hasQuotationMarks ?titleStated ?authorStated ?author2
        WHERE {
            <${decodedUri}> a oa:Annotation ;
                            oa:hasTarget ?target ;
                            dcterms:created ?created ;
                            oa:hasBody ?body .

            ?target a oa:SpecificResource ;
                    oa:hasSelector ?rangeSelector ;
                    oa:hasSource ?source .

            ?body a intro:INT7_Interpretament ;
                    sor:note ?note ;
                    sor:firstProposer ?firstProposer ;
                    intro:R21_identifies ?r21_identifies ;
                    sor:author ?author ;
                    sor:literarySource ?literarysource ;
                    sor:notedIn ?notedin ;
                    sor:egotext ?egotext .

            
            ?r21_identifies a intro:INT3_IntertextualRelationship ;
                    intro:R19_has_type sorvoc:DirectReference ;
                    sor:hasFormalAppearance ?formalAppearance .

            ?formalAppearance a sor:FormalAppearance ;
                    sor:hasQuotationMarks ?hasQuotationMarks ;
                    sor:titleStated ?titleStated ;
                    sor:authorStated ?authorStated ;
                    sor:literarySource ?literarySource ;
                    sor:author ?author2 .

            ?egotext a sor:EgotextPassage;
                    bf:part ?egotextpart ;
                    bf:partOf ?instance .

            ?instance a bf:Instance ;
                    bf:partOf ?work ;
                    bf:electronicLocator ?electronicLocator;
                    sor:pages ?sorpages ;
                    bf:identifiedBy ?identifiedBy.

            ?literarysource a sor:SourcePassage ;
                    intro:R44_has_wording ?has_wording ;
                    sor:context ?sorcontext ;
                    bf:part ?resourcepart ;
                    sor:originalLink ?originalLink ;
                    sor:modifiedLink ?modifiedLink ;
                    bf:partOf ?sourcepartof.

            ?author foaf:name ?authorName .

            ?rangeSelector a oa:RangeSelector ;
                            oa:hasStartSelector ?startSelector ;
                            oa:hasEndSelector ?endSelector .

            ?startSelector rdf:value ?startValue .
            ?endSelector rdf:value ?endValue .

            OPTIONAL {
                ?rangeSelector oa:refinedBy ?dataPositionSelector .
                ?dataPositionSelector a oa:DataPositionSelector ;
                                    oa:start ?startOffset ;
                                    oa:end ?endOffset .
            }

            OPTIONAL {
                ?rangeSelector oa:refinedBy ?textQuoteSelector .
                ?textQuoteSelector a oa:TextQuoteSelector ;
                                oa:exact ?exact .
            }

            OPTIONAL {
                ?rangeSelector oa:refinedBy ?xmlIDSelector .
                ?xmlIDSelector a sor:XMLIDSelector ;
                            sor:startID ?startID ;
                            sor:endID ?endID .
            }

            OPTIONAL { ?target oa:hasSource ?source . }
            OPTIONAL { ?target a ?targetType . }
        }
    `;
};

export {
        AllAnnotationsQuery,
        AnnotationsCountQuery,
        PropertiesCountQuery,
        ObjectsCountQuery,
        RecentAnnotationsQuery,
        CompleteAnnotationsQuery,
        SourcePassages,
        EgotextPassages,
        ResearchPassages,
        Instances,
        Works,
        Persons,
        AnnotationDetailsQuery
};