// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  ItalicOutlined,
  SearchOutlined,
  UserOutlined,
  FullscreenExitOutlined,
  AlignCenterOutlined,
  BookOutlined,
  CopyOutlined,
  ClusterOutlined,
  ProfileOutlined
} from '@ant-design/icons';

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  ItalicOutlined,
  SearchOutlined,
  UserOutlined,
  FullscreenExitOutlined,
  AlignCenterOutlined,
  BookOutlined,
  CopyOutlined,
  ClusterOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const search = {
  id: 'search',
  title: 'Search',
  type: 'group',
  children: [
    {
      id: 'search-annotation',
      title: 'Search Annotations',
      type: 'item',
      url: '/searchannotation',
      icon: icons.ItalicOutlined
    },
    {
      id: 'search-author',
      title: 'Search Authors',
      type: 'item',
      url: '/searchauthor',
      icon: icons.UserOutlined
    },
    {
      id: 'search-source',
      title: 'Search Source',
      type: 'item',
      url: '/searcsource',
      icon: icons.FullscreenExitOutlined
    },
    {
      id: 'search-egotext',
      title: 'Search Egotext',
      type: 'item',
      url: '/searchegotext',
      icon: icons.AlignCenterOutlined
    },
    {
      id: 'search-research',
      title: 'Search Research',
      type: 'item',
      url: '/searchresearch',
      icon: icons.ProfileOutlined
    },
    {
      id: 'search-instance',
      title: 'Search Instance',
      type: 'item',
      url: '/searchinstance',
      icon: icons.ClusterOutlined
    },
    {
      id: 'search-work',
      title: 'Search Work',
      type: 'item',
      url: '/searchwork',
      icon: icons.BookOutlined
    },
    {
      id: 'search-person',
      title: 'Search Person',
      type: 'item',
      url: '/searchperson',
      icon: icons.UserOutlined
    }
  ]
};

export default search;
