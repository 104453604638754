// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Popover, Container, Button } from "@mui/material";
import ParsedXML from "../data/ParsedXML";
import type { Store } from "n3";
import type { SparqlConfig } from "../sparql/update";
import SingleFrame from "./SingleFrame";
import teiText from "../data/Text";
import rdfData from "../data/rdfData";
import type { Quad } from "@rdfjs/types";
import { parsePromise } from "@fe/roger-core";
import * as N3 from "n3";
import axios from "axios";
import Prefixes from '../data/Prefixes';
import Query from "../data/Queries";



interface TeiXmlProps {
  schema: Store;
  idNamespace?: string;
  config?: SparqlConfig;
}

export default function TeiXml({
  schema: schemaStore,
  idNamespace = "http://example.org/",
  config,
}: TeiXmlProps) {
  const xml = teiText;
  const schemaRef = useRef<HTMLDivElement | null>(null);

  interface AnnotationData {
    selectedText?: string;
    dataInfo?: string;
    startOffset?: number;
    endOffset?: number;
    targetId?: string;
  }

  const [initialQuads, setInitialQuads] = useState<Array<Quad>>([]);
  const [endData, setEndData] = useState<AnnotationData>({});
  const [combinedData, setCombinedData] = useState<{
    startData: AnnotationData;
    endData: AnnotationData;
  }>({
    startData: { dataInfo: "", targetId: "" },
    endData: { dataInfo: "", targetId: "" },
  });
  const [startData, setStartData] = useState<AnnotationData>({});
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [visibleText, setVisibleText] = useState<string[]>([]);
  const [currentChunk, setCurrentChunk] = useState<number>(1);
  const [results, setResults] = useState([]);

  const chunkSize = 80000; // Number of characters per chunk


  useEffect(() => {
    const newCombinedData = {
      startData: { ...startData },
      endData: { ...endData },
    };
    setCombinedData(newCombinedData);
  }, [startData, endData]);

  const handleSelection = () => {
    if (!window.getSelection) return;

    const selection = window.getSelection();
    console.log('selection', selection);
    if (selection && selection.rangeCount > 0) {
      const selectedText = selection.toString().trim(); // Trim to remove leading/trailing spaces
      console.log('selectedText', selectedText);
      if (selectedText) {
        const range = selection.getRangeAt(0);
        const startNode = range.startContainer.parentNode as HTMLElement;
        const endNode = range.endContainer.parentNode as HTMLElement;

        if (
          schemaRef.current?.contains(startNode) &&
          schemaRef.current?.contains(endNode)
        ) {
          const selectionStartData: AnnotationData = {
            dataInfo: decodeURIComponent(startNode.getAttribute("sameas") || ""),
            startOffset: range.startOffset,
            targetId: startNode.getAttribute("id") || "",
          };
          setStartData(selectionStartData);

          const handleMouseUp = () => {
            const selectedTextWithoutNewlines = range.toString().replace(/\n/g, ''); // Remove newlines

            const selectionEndData: AnnotationData = {
              selectedText: selectedTextWithoutNewlines,
              dataInfo: decodeURIComponent(endNode.getAttribute("sameas") || ""),
              endOffset: range.endOffset,
              targetId: endNode.getAttribute("id") || "",
            };
            setEndData(selectionEndData);

            // Open the popover when the user selects text
            setPopoverAnchor(endNode as HTMLElement);

            document.removeEventListener("mouseup", handleMouseUp);
          };

          document.addEventListener("mouseup", handleMouseUp);
        }
      }
    }
  };

  const handleClosePopover = () => {
    // Close the popover
    setPopoverAnchor(null);
  };

  const parseResults = (results) => {
    return results.map(binding => ({
      annotation: binding.annotation.value,
      target: binding.target.value,
      startSelector: binding.startSelector.value,
      startValue: binding.startValue.value,
      endSelector: binding.endSelector.value,
      endValue: binding.endValue.value,
      dataPositionSelector: binding.dataPositionSelector?.value ?? null,
      startOffset: binding.startOffset ? parseInt(binding.startOffset.value, 10) : null,
      endOffset: binding.endOffset ? parseInt(binding.endOffset.value, 10) : null,
      source: binding.source.value,
      textQuoteSelector: binding.textQuoteSelector?.value ?? null,
      exact: binding.exact?.value ?? null,
      xmlIDSelector: binding.xmlIDSelector?.value ?? null,
      startID: binding.startID?.value ?? null,
      endID: binding.endID?.value ?? null,
      note: binding.note?.value ?? null,
      author: binding.author?.value,
      authorName: binding.authorName?.value ?? null,
      created: binding.created?.value ?? null,
      literarysource: binding.literarysource?.value ?? null,
      notedin: binding.notedin?.value ?? null,
      egotext: binding.egotext?.value ?? null,
      egotextpart: binding.egotextpart?.value ?? null,
      instance: binding.instance?.value ?? null,
      work: binding.work?.value ?? null,
      electronicLocator: binding.electronicLocator?.value ?? null,
      sorpages: binding.sorpages?.value ?? null,
      identifiedBy: binding.identifiedBy?.value ?? null,
      has_wording: binding.has_wording?.value ?? null,
      sorcontext: binding.sorcontext?.value ?? null,
      resourcepart: binding.resourcepart?.value ?? null,
      originalLink: binding.originalLink?.value ?? null,
      modifiedLink: binding.modifiedLink?.value ?? null,
      sourcepartof: binding.sourcepartof?.value ?? null,
    }));
  };

  const annotations = parseResults(results);

  useEffect(() => {
    const annotationData = rdfData(combinedData);
    const parser = new N3.Parser();
    parser.parse(annotationData, (error, quad, prefixes) => {
      if (error) {
        console.error("Error parsing RDF data:", error);
      }
    });

    const parseAndProcess = async () => {
      const store = new N3.Store();
      const parser = new N3.Parser();
      await parsePromise(parser, annotationData, store);
      const dataQuads = store.getQuads();
      setInitialQuads(dataQuads);
    };
    parseAndProcess();

    const fetchData = async () => {
      try {
        const query = `
                            ${Prefixes}
                            ${Query}
                        `;
        const headers = {
          'Content-Type': 'application/sparql-query'
        };
        const url = `${process.env.REACT_APP_API_SPARQL}`;

        const response = await axios.post(url, query, { headers });
        setResults(response.data.results.bindings);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [combinedData]);

  useEffect(() => {
    const loadInitialChunks = () => {
      const initialChunk = xml.slice(0, chunkSize);
      setVisibleText([initialChunk]);
    };
    loadInitialChunks();
  }, [xml]);

  const loadMoreChunks = () => {
    const nextChunk = xml.slice(currentChunk * chunkSize, (currentChunk + 1) * chunkSize);
    setVisibleText(prev => [...prev, nextChunk]);
    setCurrentChunk(currentChunk + 1);
  };

  const highlightText = (element, startOffset, endOffset) => {
    const text = element.textContent;
    const beforeHighlight = text.slice(0, startOffset);
    const highlighted = text.slice(startOffset, endOffset);
    const afterHighlight = text.slice(endOffset);

    // Create the tei-annotation and tei-seg elements
    const annotationElement = document.createElement("tei-annotation");
    const segElement = document.createElement("tei-seg");
    segElement.textContent = highlighted;
    annotationElement.appendChild(segElement);

    console.log('afterSeg', annotationElement);
    // Create new tei-seg elements for before and after highlighted text
    const beforeSeg = document.createElement("tei-seg");
    beforeSeg.textContent = beforeHighlight;
    beforeSeg.setAttribute('sameas', element.getAttribute('sameas'));

    //console.log('beforeSeg', beforeSeg);

    const afterSeg = document.createElement("tei-seg");
    afterSeg.textContent = afterHighlight;
    afterSeg.setAttribute('sameas', element.getAttribute('sameas'));
    afterSeg.setAttribute('id', element.getAttribute('id') + '-after');

    //console.log('afterSeg', afterSeg);


    // Insert the new elements before the original tei-seg
    const parent = element.parentNode;
    //parent.insertBefore(beforeSeg, element);
    // parent.insertBefore(annotationElement, element);
    // parent.insertBefore(afterSeg, element);
    // parent.removeChild(element);
  };




  const highlightAcrossElements = (startElement, startOffset, endElement, endOffset) => {
    const startText = startElement.textContent;
    const endText = endElement.textContent;

    const beforeHighlight = startText.slice(0, startOffset);
    const highlightedStart = startText.slice(startOffset);
    const highlightedEnd = endText.slice(0, endOffset);
    const afterHighlight = endText.slice(endOffset);

    // Create the tei-annotation element for the start part
    const annotationStartElement = document.createElement("tei-annotation");
    const segStartElement = document.createElement("tei-seg");
    segStartElement.textContent = highlightedStart;
    annotationStartElement.appendChild(segStartElement);

    // Create the tei-annotation element for the end part
    const annotationEndElement = document.createElement("tei-annotation");
    const segEndElement = document.createElement("tei-seg");
    segEndElement.textContent = highlightedEnd;
    annotationEndElement.appendChild(segEndElement);

    // Create new tei-seg elements for before and after highlighted text
    const beforeSeg = document.createElement("tei-seg");
    beforeSeg.textContent = beforeHighlight;
    beforeSeg.setAttribute('sameas', startElement.getAttribute('sameas'));

    const afterSeg = document.createElement("tei-seg");
    afterSeg.textContent = afterHighlight;
    afterSeg.setAttribute('sameas', endElement.getAttribute('sameas'));
    afterSeg.setAttribute('id', endElement.getAttribute('id') + '-after');

    // Insert the new elements around the original tei-seg elements
    const startParent = startElement.parentNode;
    startParent.insertBefore(beforeSeg, startElement);
    startParent.insertBefore(annotationStartElement, startElement);
    startParent.removeChild(startElement);

    const endParent = endElement.parentNode;
    endParent.insertBefore(annotationEndElement, endElement);
    endParent.insertBefore(afterSeg, endElement);
    endParent.removeChild(endElement);
  };


  useEffect(() => {
    annotations.forEach(annotation => {
      const startValueEncoded = annotation.startValue.replace(/\[/g, '%5B').replace(/\]/g, '%5D');
      const endValueEncoded = annotation.endValue.replace(/\[/g, '%5B').replace(/\]/g, '%5D');

      const startElements = document.querySelectorAll(`[sameas="${startValueEncoded}"]`);
      const endElements = document.querySelectorAll(`[sameas="${endValueEncoded}"]`);

      startElements.forEach(startElement => {
        endElements.forEach(endElement => {
          if (annotation.startOffset !== null && annotation.endOffset !== null) {
            if (startElement === endElement) {
              highlightText(startElement, annotation.startOffset, annotation.endOffset);
            } else {
              highlightAcrossElements(startElement, annotation.startOffset, endElement, annotation.endOffset);
            }
          }
        });
      });
    });
  }, [annotations]);



  return (
    <>
      <Container maxWidth="xl" style={{ padding: '2rem' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} sx={{
            border: 1,
            borderColor: 'grey.200',
            borderRadius: 2,
            boxShadow: 3,
            padding: 2
          }}>
            <div
              ref={schemaRef}
              onMouseUp={handleSelection}
              dangerouslySetInnerHTML={{ __html: xml }}
            />
            <Popover
              sx={{
                m: -10,
                maxHeight: '600px',
              }}
              open={Boolean(popoverAnchor)}
              anchorEl={popoverAnchor}
              onClose={handleClosePopover}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              disableScrollLock={true}
            >
              <Box
                sx={{
                  p: 2,
                  m: 1,
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                  borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                  borderRadius: 2,
                  textAlign: 'left',
                  lineHeight: 1.5,
                  minWidth: '600px',
                  maxWidth: '900px',
                  overflow: 'auto',
                }}
              >
                <p>
                  <span style={{ fontWeight: 700, color: 'red' }}>
                    See Annotation Target at console{' '}
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 700 }}>Selected Text: </span>
                  <span style={{ color: 'black' }}>
                    {combinedData.endData.selectedText}
                  </span>
                </p>
              </Box>
              <Box
                sx={{
                  py: 12,
                  m: 1,
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                  borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                  borderRadius: 2,
                  textAlign: 'left',
                  lineHeight: 1.5,
                  minWidth: '600px',
                  maxWidth: '900px',
                  overflow: 'auto',
                }}
              >
                <SingleFrame
                  initialQuads={initialQuads}
                  schema={schemaStore}
                  idNamespace={idNamespace}
                  config={config}
                />
              </Box>
            </Popover>
            <ParsedXML xml={xml} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
