// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react';
import { Container, Typography } from '@mui/material';


import SourcePassages from '../../api/tables/SourcePassages.tsx';




const SourcePassageTable: React.FC = () => {


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Source Passages
            </Typography>
            <SourcePassages />
        </Container>
    );
};

export default SourcePassageTable;

