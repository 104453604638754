// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import { createBrowserRouter } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes.jsx';
import LoginRoutes from './LoginRoutes.jsx';

// ==============================|| ROUTING RENDER ||============================== //

const router = (schemaStore) => createBrowserRouter([
    MainRoutes(schemaStore),
    LoginRoutes
], { basename: '/' });

export default router;
