// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react';
import { Container, Typography } from '@mui/material';


import Authors from '../../api/tables/Annotations.tsx';




const AuthorTable: React.FC = () => {


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Annotators
            </Typography>
            <Authors />


        </Container>
    );
};

export default AuthorTable;

