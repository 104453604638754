// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Instances as InstancesQuery } from '../../data/Queries.ts';
import { FetchData } from '../FetchData.tsx';

const parseInstances = (results) => {

    return results.map((binding, index) => ({
        id: index,
        instance: binding.instance.value,
        name: binding.name?.value ?? null,
        workmaintitle: binding.workmaintitle?.value,
        worksubtitle: binding.worksubtitle?.value ?? null,
        instancemaintitle: binding.instancemaintitle?.value ?? null,
        instancesubtitle: binding.instancesubtitle?.value ?? null,
    }));
};

const deleteInstance = async (instanceData) => {
    try {
        const subject = instanceData.instance;
        const url = `${process.env.REACT_APP_API_STATEMENTS}`;
        const deleteQuery = `
            DELETE WHERE {
                <${subject}> ?p ?o .
            }
        `;
        const headers = {
            'Content-Type': 'application/sparql-update'
        };
        await axios.post(url, deleteQuery, { headers });
    } catch (err) {
        console.log('Error deleting row');
    }
}

const Instances: React.FC = () => {
    const [instances, setInstances] = useState([]);

    useEffect(() => {
        const Request = {
            query: InstancesQuery,
            headers: {
                'Content-Type': 'application/sparql-query'
            },
            url: `${process.env.REACT_APP_API_SPARQL}`
        };
        const fetch = async () => {
            const result = await FetchData(Request);
            const bindings = result?.data.results.bindings;
            const instances = parseInstances(bindings);
            setInstances(instances);
        };

        fetch();
    }, []);


    const handleDelete = async (instance) => {
        await deleteInstance(instance);
        setInstances((prevInstances) => prevInstances.filter((item) => item.instance !== instance.instance));
        alert('Source Passage deleted successfully!');
    };

    const columns = [
        { field: 'instancemaintitle', headerName: 'Instance Main Title', width: 200 },
        { field: 'workmaintitle', headerName: 'Work Main Title', width: 200 },
        { field: 'worksubtitle', headerName: 'Work Subtitle', width: 200 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'haswording', headerName: 'Has Wording', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(params.row)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid rows={instances} columns={columns} initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 5,
                    },
                },
            }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick />
        </Box>
    );
};

export default Instances;
