// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from 'react';
import { Container, Typography } from '@mui/material';


import Annotations from '../../api/tables/Annotations.tsx';

const AnnotationsTable: React.FC = () => {

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Annotations
            </Typography>
            <Annotations />
        </Container>
    );
};

export default AnnotationsTable;

